import { CLEAR_CAMERA, SET_CAMERA } from 'camera-module/camera/core/cameraActionTypes'
import cameraApi from 'camera-module/camera/api/cameraApi'

const get = id => dispatch => {
  return cameraApi.getById(id)
    .then(camera => dispatch(set(camera)))
}

const update = id => (dispatch, getState) => {
  const { selectedCamera } = getState()

  if (selectedCamera && selectedCamera.fetching) {
    return
  }

  return cameraApi
    .getById(id)
    .then(camera => dispatch(set(camera)))
}

const set = (camera) => ({ type: SET_CAMERA, camera: camera })
const updateSettings = (camera, newSettings) => ({ type: SET_CAMERA, camera: { ...camera, config: newSettings } })
const clear = () => ({ type: CLEAR_CAMERA })

const cameraActions = { get, set, updateSettings, clear, update }
export default cameraActions
