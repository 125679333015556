import { getAddOnId } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'

const buildCameraAddons = addons => addons
  .filter(a => getAddOnId(a.id) !== undefined)
  .map(buildCameraAddon)

const buildCameraAddon = addon => ({
  id: getAddOnId(addon.id),
  price: addon.price || null,
  minutesOfSdStreaming: addon.minutesSd || null,
  minutesOfHdStreaming: addon.minutesHd || null,
  photoCount: addon.photoCount || null,
  videoCount: addon.videoCount || null,
  eligiblePlans: addon.eligiblePlans || [],
  eligibleCameras: addon.eligibleCameras || [],
})

const buildCameraPlans = plansData => ({
  addOns: buildCameraAddons(plansData.addOns),
  plans: plansData.plans?.map(plan => ({ ...plan })),
})

export { buildCameraPlans, buildCameraAddon }
