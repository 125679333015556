import { useEffect, useRef, useState } from 'react'

import helper from './cameraSettingFixer'
import cameraApi from 'camera-module/camera/api/cameraApi'
import { isFormTouched } from 'form-module/ui/util'

export const fetchCameraSettings = async (camera, form) => await cameraApi
  .getSettings(camera.id, form.values?.captureMode, form.values?.operationMode)
  .then(data => helper.fix(helper.build(data), form.values))

export const savesSettings = async (form, setSettings, setHandlesSubmit) => {
  setHandlesSubmit(true)
  isFormTouched(form) && await form.submitForm().then(() => setSettings(undefined))
}

export const useCameraSettings = (camera, form) => {
  const [settings, setSettings] = useState()
  const [handlesSubmit, setHandlesSubmit] = useState(false)
  const formValuesHasChanged = settings && (form.values.captureMode !== settings.valueOf('captureMode').currentValue || form.values.operationMode !== settings.valueOf('operationMode').currentValue)

  useEffect(() => {
    if (!settings) fetchCameraSettings(camera, form).then(setSettings).then(() => setHandlesSubmit(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, camera.id])

  useEffect(() => {
    if (formValuesHasChanged) !handlesSubmit && savesSettings(form, setSettings, setHandlesSubmit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.captureMode, form.values?.operationMode])

  return settings
}

// Custom Hook for initial load
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
