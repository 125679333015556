import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const ProvinceSelectionIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="139" height="156" viewBox="0 0 139 156" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M134.063 15.79s8.675 61.507 2.882 77.82c-5.406 15.37-28.949 38.18-45.324 49.273l-6.91 4.523.004.012c-4.863 3.133-9.988 5.996-15.164 8.582-6.344-3.191-12.598-6.742-18.531-10.637-16.114-9.886-43.063-35.11-48.86-51.59C-3.637 77.297 5.043 15.79 5.043 15.79L69.551.922Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.icon?.iconConsent}
        fillOpacity={1}
      />

      <path
        d="M25.89 70.605a35.058 35.058 0 0 0 2.094 6.832c5.336 12.61 17.82 21.458 32.375 21.458 2.93 0 5.782-.36 8.508-1.036v7.473c0 1.938.664 3.887 2.145 5.367 1.476 1.477 3.43 2.14 5.363 2.14h25.395c1.933 0 3.882-.663 5.363-2.14 1.48-1.48 2.14-3.43 2.14-5.367V86.289c0-1.934-.66-3.887-2.14-5.367a7.064 7.064 0 0 0-1.031-.852v-3.304c0-7.282-4.368-13.395-10.723-15.86a34.946 34.946 0 0 0-.484-3.652c-3.051-16.297-17.352-28.629-34.536-28.629-16.675 0-30.636 11.617-34.234 27.2a35.192 35.192 0 0 0-.902 7.937c0 2.34.23 4.633.668 6.843Zm45.977 23.168a32.47 32.47 0 0 1-2.672.895c-.074.02-.152.043-.226.062l-.102.028a32.15 32.15 0 0 1-8.508 1.137c-17.746 0-32.136-14.387-32.136-32.133 0-17.75 14.39-32.137 32.136-32.137 17.54 0 31.797 14.05 32.13 31.512a13.753 13.753 0 0 1 9.038 7.054c1.008 1.95 1.575 4.184 1.575 6.575v5.187a4.258 4.258 0 0 1 1.91 1.09c.281.285.515.602.707.945.37.68.554 1.469.554 2.301v19.117a4.81 4.81 0 0 1-.398 1.906 4.163 4.163 0 0 1-.863 1.266c-.84.836-1.989 1.262-3.242 1.262H76.375c-1.254 0-2.402-.426-3.242-1.262a4.268 4.268 0 0 1-.688-.906 4.712 4.712 0 0 1-.57-2.106c-.004-.078-.008-.156-.008-.234Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette?.checkbox?.primary}
        fillOpacity={1}
      />

      <path
        d="M60.36 31.625c-17.747 0-32.137 14.387-32.137 32.137 0 17.746 14.39 32.133 32.136 32.133 17.75 0 32.137-14.387 32.137-32.133 0-17.75-14.387-32.137-32.137-32.137Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.background?.dark}
        fillOpacity={1}
      />

      <path
        d="m50.926 50.809-.328 1.496h19.527l-.328-1.496c-.574-2.606-1.317-4.965-2.184-6.996-1.078-2.512-2.316-4.43-3.601-5.7-1.278-1.265-2.512-1.793-3.649-1.793-1.14 0-2.375.528-3.652 1.793-1.285 1.27-2.523 3.188-3.602 5.7-.867 2.03-1.609 4.39-2.183 6.996Zm0 0"
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.icon?.iconConsent}
        fillOpacity={1}
      />

      <path
        d="m35.328 52.305 1.016-1.828a27.536 27.536 0 0 1 13.015-11.864l3.227-1.414-1.64 3.117c-.36.68-.696 1.395-1.016 2.133-1.098 2.567-1.996 5.563-2.645 8.86l-.191.996Zm32.809-15.106 3.226 1.414A27.536 27.536 0 0 1 84.38 50.477l1.016 1.828H73.629l-.192-.996c-.648-3.297-1.546-6.293-2.644-8.86a30.774 30.774 0 0 0-1.016-2.133ZM34.086 55.766h12.492l-.133 1.351a69.238 69.238 0 0 0-.316 6.645c0 2.273.11 4.496.316 6.64l.133 1.352H34.086l-.246-.914a27.495 27.495 0 0 1-.922-7.078c0-2.45.32-4.82.922-7.078Zm15.98 0h20.59l.117 1.101c.235 2.2.36 4.508.36 6.895a65.11 65.11 0 0 1-.36 6.89l-.117 1.102h-20.59l-.117-1.102a65.11 65.11 0 0 1-.36-6.89c0-2.387.126-4.696.36-6.895Zm24.079 0h12.492l.246.918a27.45 27.45 0 0 1 .922 7.078c0 2.445-.32 4.816-.922 7.078l-.246.914H74.145l.132-1.352c.207-2.144.317-4.367.317-6.64 0-2.278-.11-4.5-.317-6.645ZM35.328 75.215h11.766l.191.996c.649 3.3 1.547 6.293 2.645 8.86.32.742.656 1.452 1.015 2.132l1.64 3.117-3.226-1.414a27.528 27.528 0 0 1-13.015-11.86Zm15.27 0h19.527l-.328 1.5c-.574 2.605-1.317 4.96-2.184 6.992-1.078 2.512-2.316 4.434-3.601 5.703-1.278 1.262-2.512 1.793-3.653 1.793-1.136 0-2.37-.531-3.648-1.793-1.285-1.27-2.523-3.191-3.602-5.703-.867-2.031-1.609-4.387-2.183-6.992Zm23.03 0h11.767l-1.016 1.832a27.528 27.528 0 0 1-13.016 11.86l-3.226 1.413 1.64-3.117c.36-.68.696-1.39 1.016-2.133 1.098-2.566 1.996-5.558 2.644-8.86Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.icon?.iconConsent}
        fillOpacity={1}
      />

      <path
        d="M60.36 31.523c-17.801 0-32.235 14.434-32.235 32.235 0 17.804 14.434 32.238 32.234 32.238 4.055 0 7.938-.75 11.512-2.117v11.453c0 1.254.422 2.406 1.262 3.242.84.84 1.988 1.266 3.246 1.266h25.39c1.254 0 2.407-.426 3.243-1.266.84-.836 1.265-1.988 1.265-3.242V86.289c0-1.258-.425-2.406-1.265-3.246a4.202 4.202 0 0 0-1.91-1.09v-5.187c0-6.664-4.391-12.086-10.508-13.606-.32-17.527-14.63-31.637-32.235-31.637Zm30.894 31.372c-.457-16.668-14.113-30.04-30.895-30.04-17.066 0-30.902 13.836-30.902 30.903 0 17.07 13.836 30.906 30.902 30.906 4.07 0 7.953-.789 11.512-2.215v-6.16c0-1.258.422-2.406 1.262-3.246a4.222 4.222 0 0 1 1.91-1.09v-5.187c0-7.887 6.144-14.032 14.031-14.032.742 0 1.469.055 2.18.16Zm-18.05 28.98v-5.586c0-1.906 1.269-3.176 3.175-3.176v-6.347c0-7.149 5.547-12.696 12.695-12.696.746 0 1.477.059 2.188.176-.192 12.277-7.54 22.817-18.059 27.629Zm0 1.46C84.39 88.474 92.28 77.446 92.59 64.54c5.37 1.48 9.18 6.305 9.18 12.227v6.347c1.906 0 3.171 1.27 3.171 3.176v19.043c0 1.902-1.265 3.176-3.171 3.176H76.379c-1.906 0-3.176-1.274-3.176-3.176Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.text?.contrastText}
        fillOpacity={1}
      />

      <path
        d="M101.77 108.508c1.906 0 3.171-1.27 3.171-3.176V86.289c0-1.906-1.265-3.176-3.171-3.176v-6.347c0-7.149-5.547-12.696-12.696-12.696-7.148 0-12.695 5.547-12.695 12.696v6.347c-1.906 0-3.176 1.27-3.176 3.176v19.043c0 1.906 1.27 3.176 3.176 3.176Zm-6.348-31.742v6.347H82.727v-6.347c0-3.641 2.703-6.348 6.347-6.348 3.64 0 6.348 2.707 6.348 6.348Zm-6.34 21.296a2.347 2.347 0 1 0 0-4.694 2.347 2.347 0 0 0 0 4.695Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.background?.dark}
        fillOpacity={1}
      />

      <path
        d="M95.422 83.113v-6.347c0-3.641-2.707-6.348-6.348-6.348-3.644 0-6.347 2.707-6.347 6.348v6.347Zm0 0"
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.icon?.iconConsent}
        fillOpacity={1}
      />
    </SvgIcon>

  )
}

export default ProvinceSelectionIcon
