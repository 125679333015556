import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@mui/material/Backdrop'
import useTheme from '@material-ui/core/styles/useTheme'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import PaperPlaneIcon from 'camera-module/cameras/ui/cameras-page/icons/PaperPlaneIcon'
import SadFaceIcon from 'camera-module/cameras/ui/cameras-page/icons/SadFaceIcon'

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
    padding: 20,
    borderRadius: 6,
    minHeight: 'fit-content',
  },
  dialogContent: {
    padding: '12px !important',
    textAlign: 'left',
  },
  dialogAction: {
    padding: '12px !important',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  title: {
    '&.MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.h6?.fontSize,
      fontWeight: 600,
      fontFamily: theme.typography.h6?.fontFamily,
    },
  },
  text: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.body1?.fontFamily,
    },
    textAlign: 'left',
  },
  textFinal: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.body1?.fontFamily,
      marginBottom: 12,
    },
  },
  select: {
    marginTop: 5,
    marginBottom: 10,
    width: '100%',
    backgroundColor: theme.palette.background?.paper,
  },
  confirmButton: {
    width: '50%',
    marginRight: 5,
    marginBottom: 5,
  },
  negativeButton: {
    marginLeft: 5,
    width: '35%',
  },
}))

const paperProps = theme => {
  return {
    style: {
      maxWidth: 410,
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
      borderRadius: 6,
    },
  }
}

const slotProps = theme => {
  return {
    backdrop: { sx: { background: theme.palette.background?.shadow + 'B8' } },
  }
}

const ConsentPositiveFinal = ({ open, onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={paperProps(theme)}
      slots={{ backdrop: Backdrop }}
      slotProps={slotProps(theme)}
    >
      <Box className={classes.dialogWrapper}>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.iconWrapper}>
            <PaperPlaneIcon />
          </Box>
          <Typography className={classes.title} variant="h6">
            { t('app:marketing_consent.stay_in_touch') }
          </Typography>
          <Typography className={classes.text} variant="body1" sx={{ mt: 0.45 }}>
            { t('app:marketing_consent.stay_text') }
          </Typography>
        </DialogContent>
        <DialogActions>
          <VoskerPrimaryButton size="large" type="submit" className={classes.confirmButton} onClick={onClose} path="/">
            { t('app:marketing_consent.great_button') }
          </VoskerPrimaryButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

const ConsentNegativeFinal = ({ open, onClose, onPrimaryAction }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={paperProps(theme)}
      slots={{ backdrop: Backdrop }}
      slotProps={slotProps(theme)}
    >
      <Box className={classes.dialogWrapper}>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.iconWrapper}>
            <SadFaceIcon />
          </Box>
          <Typography className={classes.title} variant="h6">
            { t('app:marketing_consent.miss_title') }
          </Typography>
          <Typography className={classes.text} variant="body1" sx={{ mt: 0.45 }}>
            { t('app:marketing_consent.miss_text') }
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <VoskerSecondaryButton noWrap size="large" className={classes.negativeButton} type="submit" onClick={onClose} path="/">
            { t('app:marketing_consent.gotit_button') }
          </VoskerSecondaryButton>
          <VoskerPrimaryButton noWrap size="large" className={classes.negativeButton} type="submit" onClick={onPrimaryAction}>
            { t('app:marketing_consent.optin_button') }
          </VoskerPrimaryButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

const ConsentNegativeFinalSettings = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={paperProps(theme)}
      slots={{ backdrop: Backdrop }}
      slotProps={slotProps(theme)}
    >
      <Box className={classes.dialogWrapper}>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.iconWrapper}>
            <SadFaceIcon />
          </Box>
          <Typography className={classes.title} variant="h6">
            { t('app:marketing_consent.miss_title') }
          </Typography>
          <Typography className={classes.textFinal} variant="body1" sx={{ mt: 0.45 }}>
            { t('app:marketing_consent.miss_text_settings') }
          </Typography>
        </DialogContent>
        <DialogActions>
          <VoskerPrimaryButton noWrap size="large" type="button" className={classes.confirmButton} onClick={onConfirm}>
            { t('app:marketing_consent.gotit_button') }
          </VoskerPrimaryButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export { ConsentPositiveFinal, ConsentNegativeFinal, ConsentNegativeFinalSettings }
