import apm from 'shared-module/monitoring/apm'
import restapi from 'shared-module/api/restapi'
import buildCamera, { buildCameraSettings } from 'camera-module/camera/api/cameraBridge'
import handleError from 'shared-module/api/apiError'
import promoCodesApi from 'user-module/promo-code/api/promoCodesApi'

const add = async (camera) => {
  if (camera.referrer && camera.ucid) {
    await promoCodesApi.verifyEmail(camera.referrer, camera.ucid)
  }

  if (camera.promocode && camera.ucid) {
    await promoCodesApi.verify(camera.promocode)
  }

  return postCamera({ name: camera.name, modelName: camera.modelName, ucid: camera.ucid, refEmail: camera.referrer, promocode: camera.promocode, purpose: camera?.purpose })
}

const postCamera = camera => restapi.post('/api/v3/camera', camera)
  .then(({ data }) => buildCamera(data.camera || data))
  .catch(handleError)
  .finally(() => apm.log('add-camera', [{ cameraModel: camera.modelName }]))

const getAll = () => {
  return restapi.get('/api/v3/camera/all')
    .then(apiCameras => {
      apm.addLabels({ cameraCount: apiCameras.data.length })
      apm.addLabels({ cameras: apiCameras.data.map(c => c.status.model) })
      return apiCameras.data.map(buildCamera)
    })
    .catch(handleError)
}

const getById = (id) => restapi
  .get('/api/v3/camera/' + id)
  .then(apiCamera => buildCamera(apiCamera.data))
  .catch(handleError)

const remove = (id) => {
  return restapi.delete('/api/v3/camera/' + id)
    .catch(handleError)
}

const getSettings = (id, captureMode, operationMode) => {
  return restapi.get('/api/v3/camera/settingsform/' + id, {
    params: {
      captureMode,
      operationMode,
      allFields: 'true',
    },
  })
    .then(cameraSettingsForm => cameraSettingsForm.data)
    .then(buildCameraSettings)
    .catch(handleError)
}

const saveSettings = (id, settings) => {
  return restapi.put('/api/v3/camera/settings/' + id, settings)
    .then((response) => response.data?.id && buildCamera(response.data))
    .catch(handleError)
}

const resetSettings = (id) => {
  return restapi.get('/api/v3/camera/reset-settings/' + id)
    .then(cameraSettings => cameraSettings.data)
    .catch(handleError)
}

const cameraApi = {
  add: add,
  remove: remove,
  getAll: getAll,
  getById: getById,
  getSettings: getSettings,
  saveSettings: saveSettings,
  resetSettings: resetSettings,
}
export default cameraApi
