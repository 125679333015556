import { useTranslation } from 'react-i18next'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  helper: {
    color: theme.palette.status?.error || theme.palette.error?.main,
    marginLeft: 0,
  },
  asterisk: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: theme.palette.status?.error + ' !important' || theme.palette.error.main + '!important',
    },
  },
}))

const ProfileSelect = ({ form, formik, name, label, disabled, items, preferredItems, required, className, showErrorIcon, menuClassName }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <FormControl
      variant="outlined"
      disabled={disabled}
      fullWidth
      data-testid={name}
      className={className}
    >
      <InputLabel
        required={required}
        id={`${name}-label`}
        className={formik.errors[name] ? classes.asterisk : ''}
      >{ label }
      </InputLabel>
      <Select
        id={name}
        name={name}
        labelId={`${name}-label`}
        label={label}
        {...formik.getFieldProps(name)}
        onChange={(event) => {
          if (name === 'country') {
            formik.setFieldValue('territory', '').then(() => formik.handleChange(event))
            formik.setFieldValue('postalCode', '').then(() => formik.handleChange(event))
            return
          }
          formik.handleChange(event)
        }}
        error={!!formik.errors[name]}
        inputProps={{ form: form }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        { preferredItems && preferredItems.map(item => <MenuItem key={item.key} value={item.value} className={menuClassName}>{ item.name }</MenuItem>) }
        { items.map(item => <MenuItem key={item.key} value={item.value} className={menuClassName}>{ item.name }</MenuItem>) }
      </Select>

      { formik.errors[name] && (
        <FormHelperText className={classes.helper}>
          { showErrorIcon && <WarningIcon className={classes.icon} /> }
          { t(formik.errors[name]?.key || formik.errors[name]) }
        </FormHelperText>
      ) }
    </FormControl>
  )
}

export default ProfileSelect
