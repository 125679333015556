import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const StreamingIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="StreamingIcon" width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M15.816 3H1.703C.762 3 0 3.941 0 5.098v13.804C0 20.06.762 21 1.703 21h14.113c.942 0 1.704-.941 1.704-2.098V5.098C17.52 3.94 16.758 3 15.816 3ZM6.512 9.367a7.015 7.015 0 0 0-.535 2.66c.003.903.18 1.797.515 2.621.34.829.828 1.57 1.442 2.176L6.78 18a8.125 8.125 0 0 1-1.789-2.703 8.82 8.82 0 0 1-.644-3.258 8.87 8.87 0 0 1 .664-3.312A8.188 8.188 0 0 1 6.855 6l1.137 1.176a6.545 6.545 0 0 0-1.48 2.191Zm2.879 1.246a3.791 3.791 0 0 0-.286 1.414c.004.48.098.953.278 1.391.18.437.441.828.765 1.148L9 15.742a5.056 5.056 0 0 1-1.117-1.683 5.458 5.458 0 0 1-.403-2.032 5.46 5.46 0 0 1 .415-2.062c.27-.649.66-1.227 1.148-1.7L10.18 9.45a3.49 3.49 0 0 0-.79 1.164Zm1.879-.023a1.97 1.97 0 0 0-.442.648 2.09 2.09 0 0 0-.156.79c0 .265.05.53.152.773.098.246.246.465.426.644l1.395-1.418Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.background?.shadow + '!important'}
        fillOpacity={1}
      />

      <path
        d="M18.809 14.941 23.94 18V6L18.81 9.059Zm0 0"
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.background?.shadow + '!important'}
        fillOpacity={1}
      />
    </SvgIcon>
  )
}

export default StreamingIcon
