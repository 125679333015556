import { Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 16,
    marginBottom: 32,
    padding: '5px 2px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 15px',
    },
  },
}))

const ProfileFormSave = ({ setValidationOnChange = {} }) => {
  const classes = useStyles()
  const user = useUser()
  const { t } = useTranslation()
  const form = useFormikContext()

  const isDisabled = user.marketingEmail === undefined ? false : !form.dirty

  return (
    <Box className={classes.wrapper} id="saveProfileBox">
      <Grid container alignItems="flex-end" justifyContent="flex-end">
        <Grid item xs={12} md="auto">
          <VoskerPrimaryButton type="submit" onClick={setValidationOnChange} disabled={isDisabled}>
            { t('profile.save') }
          </VoskerPrimaryButton>
        </Grid>
      </Grid>
    </Box>
  )
}
export default ProfileFormSave
