const settingsStructure = {
  management: [
    { name: 'name' },
    { name: 'snoozeDetections' }, // @fixme: Not supported settings at the moment
    { name: 'schedule' },
    { name: 'smartNotifications' }, // @fixme: Not supported settings at the moment
    { name: 'warningLightsOnDetection' },
    { name: 'warningLightsPattern' },
  ],
  events: [
    { name: 'operationMode' },
    { name: 'captureMode' },
    { name: 'timeLapse' },
    { name: 'onDemandMode' }, // @fixme: Not supported settings at the moment
    { name: 'photoFirst' }, // @fixme: Not supported settings at the moment
    { name: 'delaySecond' },
    { name: 'multiShot' },
    { name: 'sensibility' },
    { name: 'triggerSpeed' },
    { name: 'irIntensity' },
    { name: 'videoLength' }, // @fixme: Not supported settings at the moment
    { name: 'timelapse' }, // @fixme: Not supported settings at the moment
    { name: 'detectionZone' },
    { name: 'detectionZoneNotificationEnabled' },
  ],
  cell: [
    { name: 'transmitTime' },
    { name: 'transmitFreq' },
    { name: 'capture' },
  ],
  general: [
    { name: 'dateFormat' },
    { name: 'timeFormat' },
    { name: 'temperatureUnit' },
    { name: 'powerMode' }, // @fixme: Confirm is this setting is still supported
    { name: 'batteryType' },
    { name: 'gps' }, // @fixme: Not supported settings at the moment
  ],
}

export default settingsStructure
