import eCommerceState from 'vosker/src/ecommerce-module/core/eCommerce.state'
import * as actions from 'vosker/src/ecommerce-module/core/eCommerce.actions'

const reducer = (state = eCommerceState.defaultState, action) => {
  switch (action.type) {
    case actions.SET_PRODUCTS:
      return eCommerceState.set(state, action)

    default:
      return state
  }
}

export default reducer
