import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const ProvinceSelectedIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="161" height="160" viewBox="0 0 161 160" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M145.063 19.79s8.675 61.507 2.878 77.82c-5.402 15.37-28.945 38.18-45.32 49.273l-6.91 4.523v.012c-4.86 3.133-9.984 5.996-15.16 8.582-6.344-3.191-12.598-6.742-18.535-10.637-16.11-9.886-43.059-35.11-48.856-51.59-5.797-16.476 2.883-77.984 2.883-77.984L80.551 4.922Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.icon?.iconConsent}
        fillOpacity={1}
      />

      <path
        d="M36.121 67.742c0-19.441 15.758-35.203 35.2-35.203 19.355 0 35.062 15.625 35.199 34.95 9.453 3.234 16.253 12.195 16.253 22.75 0 13.273-10.757 24.034-24.035 24.034-8.875 0-16.62-4.812-20.781-11.957a35.43 35.43 0 0 1-6.637.625c-19.441 0-35.199-15.761-35.199-35.199Zm43.434 31.133a32.153 32.153 0 0 1-8.235 1.066c-17.785 0-32.199-14.418-32.199-32.199 0-17.785 14.414-32.203 32.2-32.203 17.78 0 32.199 14.418 32.199 32.203 0 .668-.024 1.332-.063 1.992 9.348 2.141 16.316 10.508 16.316 20.504 0 11.617-9.418 21.035-21.035 21.035-8.535 0-15.886-5.09-19.183-12.398Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette?.checkbox?.primary}
        fillOpacity={1}
      />

      <path
        d="M71.32 35.645c-17.73 0-32.097 14.367-32.097 32.093 0 17.73 14.367 32.098 32.097 32.098 17.727 0 32.094-14.367 32.094-32.098 0-17.726-14.367-32.093-32.094-32.093Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.background?.dark}
        fillOpacity={1}
      />
      <path
        d="m46.316 56.297 1.016-1.828A27.517 27.517 0 0 1 60.328 42.62l3.227-1.41-1.64 3.113c-.36.68-.696 1.39-1.013 2.133-1.097 2.559-1.996 5.55-2.64 8.848l-.196.992Zm32.77-15.086 3.223 1.41a27.526 27.526 0 0 1 13 11.848l1.015 1.828h-11.75l-.195-.992c-.645-3.297-1.543-6.29-2.64-8.848a29.928 29.928 0 0 0-1.012-2.133ZM45.074 59.754h12.477l-.13 1.351a67.812 67.812 0 0 0-.316 6.633c0 2.274.106 4.492.317 6.637l.129 1.348H45.074l-.242-.914a27.39 27.39 0 0 1-.922-7.07c0-2.442.32-4.813.922-7.071Zm15.965 0h20.563l.117 1.101c.234 2.196.36 4.5.36 6.883s-.126 4.692-.36 6.887l-.117 1.098H61.039l-.117-1.098a65.013 65.013 0 0 1-.363-6.887c0-2.383.129-4.687.363-6.883Zm24.05 0h12.477l.243.914c.601 2.258.921 4.629.921 7.07 0 2.446-.32 4.817-.921 7.07l-.243.915H85.09l.129-1.348c.207-2.145.316-4.363.316-6.637 0-2.27-.11-4.492-.316-6.633ZM46.317 79.18h11.75l.196.996c.644 3.293 1.543 6.285 2.64 8.847a28.32 28.32 0 0 0 1.012 2.133l1.64 3.114-3.226-1.415a27.478 27.478 0 0 1-12.996-11.847Zm15.25 0h19.508l-.332 1.496c-.57 2.605-1.312 4.96-2.18 6.988-1.074 2.508-2.312 4.426-3.597 5.695-1.278 1.262-2.508 1.79-3.645 1.79-1.136 0-2.367-.528-3.648-1.79-1.281-1.27-2.52-3.187-3.594-5.695-.867-2.027-1.61-4.383-2.183-6.988Zm23.008 0h11.75l-1.015 1.828a27.487 27.487 0 0 1-13 11.847l-3.223 1.415 1.64-3.114c.356-.68.696-1.39 1.012-2.133 1.098-2.562 1.996-5.554 2.64-8.847Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.icon?.iconConsent}
        fillOpacity={1}
      />

      <path
        d="m61.895 54.8-.329 1.497h19.508l-.332-1.496c-.57-2.602-1.312-4.957-2.18-6.985-1.074-2.507-2.312-4.425-3.597-5.695-1.278-1.262-2.508-1.789-3.645-1.789-1.136 0-2.367.527-3.648 1.79-1.281 1.269-2.52 3.187-3.594 5.694-.867 2.028-1.61 4.383-2.183 6.985Zm0 0"
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.icon?.iconConsent}
        fillOpacity={1}
      />

      <path
        d="M71.316 35.54c-17.78 0-32.199 14.417-32.199 32.202 0 17.781 14.418 32.2 32.2 32.2 2.847 0 5.609-.372 8.238-1.067 3.297 7.309 10.648 12.398 19.183 12.398 11.617 0 21.035-9.418 21.035-21.035 0-9.996-6.968-18.363-16.316-20.504.04-.66.063-1.324.063-1.992 0-17.785-14.418-32.203-32.204-32.203Zm30.82 33.937c.032-.575.048-1.153.048-1.735 0-17.047-13.82-30.867-30.868-30.867-17.046 0-30.863 13.82-30.863 30.867S54.27 98.605 71.316 98.605c2.668 0 5.258-.335 7.727-.972a20.923 20.923 0 0 1-1.34-7.395c0-11.617 9.418-21.035 21.035-21.035 1.157 0 2.293.094 3.399.274ZM80.333 97.27a19.628 19.628 0 0 1-1.293-7.032c0-10.883 8.82-19.703 19.7-19.703 1.124 0 2.222.094 3.296.274-1.238 12.55-9.996 22.894-21.703 26.46Zm.52 1.234c12.105-3.746 21.16-14.442 22.496-27.426 8.656 2.074 15.093 9.863 15.093 19.16 0 10.88-8.82 19.7-19.703 19.7-7.925 0-14.761-4.68-17.886-11.434Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.text?.contrastText}
        fillOpacity={1}
      />

      <path
        d="M118.441 90.238c0 10.88-8.82 19.7-19.699 19.7-10.883 0-19.703-8.82-19.703-19.7 0-10.883 8.82-19.703 19.703-19.703 10.88 0 19.7 8.82 19.7 19.703Zm-21.859 8.016 13.133-13.133-2.903-2.902L95.13 93.902l-3.8-3.804L88.425 93l5.254 5.254a2.055 2.055 0 0 0 2.902 0Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.icon?.iconBlue}
        fillOpacity={1}
      />

      <path
        d="m88.426 93 2.902-2.902 3.8 3.804 11.684-11.683 2.903 2.902-13.133 13.133a2.055 2.055 0 0 1-2.902 0Zm6.703 1.457 11.683-11.68 2.344 2.344-12.855 12.856c-.309.312-.73.484-1.172.484-.438 0-.86-.172-1.172-.484L88.98 93l2.348-2.344Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.icon?.iconBlue}
        fillOpacity={1}
      />

      <path
        d="M95.105 94.465 106.79 82.78l2.344 2.344L96.277 97.98a1.646 1.646 0 0 1-2.344 0l-4.976-4.972 2.348-2.348Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.basic?.white}
        fillOpacity={1}
      />
    </SvgIcon>

  )
}

export default ProvinceSelectedIcon
