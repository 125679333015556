import { createTheme } from '@material-ui/core'
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes'

import { typographies } from 'vosker/src/shared-module/theme/typographies'

const colors = {
  baseWhite: '#FFFFFF',
  baseBlack: '#000000',
  baseGrey: '#51535B',
  baseLightGrey: '#F0F1F5',

  primaryColor: '#FACB2C',
  primaryLight: '#FCD656',
  primaryDark: '#F9C20A',

  secondaryColor: '#D1D5E0',
  secondaryLight: '#E0E3EB',
  secondaryDark: '#C2C6D6',

  textColor: '#1F222E',
  textContrastColor: '#5C668A',

  errorColor: '#E00303',
  errorContrastColor: '#FFF3F3',

  warningColor: '#F9C20A',
  warningContrastColor: '#FEF9EA',
  warningDarkColor: '#FEF9E7',

  cautionColor: '#E26E04',
  cautionContrastColor: '#FFF5EC',

  successColor: '#128238',
  successContrastColor: '#EDFFF3',

  infoColor: '#1B76FD',
  infoContrastColor: '#EAF1FF',

  backgroundRegular: '#F0F1F5',
  backgroundDark: '#3D445C',
  backgroundShadow: '#3D445C',
  zoneShadow: '#C3C6CF',

  borderRegular: '#E0E3EB',
  borderDark: '#858EAD',
  borderLight: '#B3B8CC',
}

const muiTheme = createTheme({
  typography: typographies,
  palette: {
    type: 'light',
    primary: {
      main: colors.primaryColor,
      light: colors.primaryLight,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondaryColor,
      light: colors.secondaryLight,
      dark: colors.secondaryDark,
    },
    text: {
      primary: colors.textColor,
      secondary: colors.textContrastColor,
      contrastText: colors.baseWhite,
      main: colors.baseWhite,
    },
    status: {
      error: colors.errorColor,
      caution: colors.cautionColor,
      warning: colors.warningColor,
      success: colors.successColor,
      info: colors.infoColor,
      background: {
        error: colors.errorContrastColor,
        caution: colors.cautionContrastColor,
        warning: colors.warningContrastColor,
        warningDark: colors.warningDarkColor,
        success: colors.successContrastColor,
        info: colors.infoContrastColor,
      },
    },
    background: {
      default: colors.backgroundRegular,
      paper: colors.baseWhite,
      contrast: colors.baseWhite,
      dark: colors.backgroundDark,
      light: colors.borderRegular,
      tile: colors.backgroundDark,
      logo: colors.baseGrey,
      shadow: colors.textColor,
      boxShadow: colors.zoneShadow,
      empty: colors.backgroundRegular,
      viewer: colors.baseBlack,
      consent: colors.baseWhite,
      dropdown: colors.borderRegular,
      gradient: colors.baseLightGrey,

    },
    border: {
      primary: colors.borderRegular,
      secondary: colors.borderDark,
      contrast: colors.borderDark,
      neutral: colors.baseBlack,
      light: colors.borderLight,
      divider: colors.secondaryColor,
    },
    basic: {
      white: colors.baseWhite,
      black: colors.baseBlack,
      grey: colors.baseGrey,
      lightGrey: colors.baseLightGrey,
      dark: colors.textColor,
    },
    icon: {
      primary: colors.borderDark,
      icon: colors.backgroundDark,
      iconDark: colors.textColor,
      iconShadow: colors.secondaryLight,
      iconPromo: colors.textColor,
      iconBlue: colors.infoColor,
      iconBorder: colors.baseLightGrey,
      iconConsent: colors.baseLightGrey,
    },
    switch: {
      background: colors.borderRegular,
      backgroundActive: colors.baseWhite,
    },
    checkbox: {
      primary: colors.secondaryDark,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: colors.textContrastColor,
        '&:hover': {
          filter: 'brightness(85%)',
        },
      },
    },
    MuiBadge: {
      colorError: {
        backgroundColor: colors.errorColor,
      },
    },
    MuiSvgIcon: {
      root: {
        fill: colors.textColor + ' !important',
      },
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 250,
        boxShadow: 'none',
        fontSize: '0.688rem !important',
        textAlign: 'center',
        color: colors.baseWhite,
        backgroundColor: colors.textColor,
      },
      arrow: {
        color: colors.textColor,
      },
    },
  },
})

const theme = responsiveFontSizes(muiTheme)
export default theme
