import { Route } from 'react-router-dom'
import { BasketProvider } from '../core/basket/BasketContext'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const BasketRoute = ({ component: Component, ...rest }) => {
  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on

  return (
    <Route
      {...rest}
      render={(props) => (
        eCommerceEnabled
          ? (
            <BasketProvider>
              <Component {...props} />
            </BasketProvider>
            )
          : (
            <Component {...props} />
            )
      )}
    />
  )
}

export default BasketRoute
