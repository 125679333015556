import eCommerceApi from 'vosker/src/ecommerce-module/api/eCommerceApi'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'vosker/src/ecommerce-module/core/eCommerce.selectors'

export const SET_PRODUCTS = 'SET_PRODUCTS'
const setProducts = products => ({ type: SET_PRODUCTS, products })

const authenticateUser = () => eCommerceApi
  .authenticate()
  .then(data => {
    localStorage.setItem(eCommerceTokenID, data.access_token)
    localStorage.setItem(eCommerceRefreshTokenID, data.refresh_token)
  })
  .catch(error => error)

const fetchProducts = () => dispatch => eCommerceApi
  .fetchPlans()
  .then(plans => eCommerceApi
    .fetchAddons()
    .then(addons => dispatch(setProducts({ plans: plans, addons: addons })))
    .catch(error => error),
  )
  .catch(error => error)

const createBaskets = () => eCommerceApi
  .createBasket()
  .then(data => data)
  .catch(error => error)

const addItemsToBasket = (basketId, Item) => eCommerceApi
  .addItemToBasket(basketId, Item)
  .then(data => data)
  .catch(error => error)

const getBasket = (basketId) => eCommerceApi
  .getBasket(basketId)
  .then(data => data)
  .catch(error => error)

const deleteBasket = (storedBasketId) => eCommerceApi
  .deleteBasket(storedBasketId)
  .then(data => data)
  .catch(error => error)

const deleteItemFromBasket = (storedBasketId, item) => eCommerceApi
  .deleteItemFromBasket(storedBasketId, item)
  .then(data => data)
  .catch(error => error)

const eCommerceActions = { authenticateUser, fetchProducts, setProducts, createBaskets, addItemsToBasket, getBasket, deleteBasket, deleteItemFromBasket }
export default eCommerceActions
