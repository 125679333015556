import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import { Box, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CameraTile from 'camera-module/cameras/ui/cameras-page/CameraTile'
import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import useCamerasActions from 'camera-module/cameras/core/useCamerasActions'
import messageActions from 'shared-module/message/messageActions'
import cameraActions from 'camera-module/camera/core/cameraActions'
import useQueryParams from 'shared-module/url/useQueryParams'
import useFreeTrialActivationModal from 'camera-module/free-trial-activation-modal/core/useFreeTrialActivationModal'
import { useCameraPlansActions } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import ActivateCameraTile from 'camera-module/cameras/ui/cameras-page/ActivateCameraTile'
import CameraIcon from 'camera-module/cameras/ui/cameras-page/icons/CameraIcon'
import AddCameraIcon from 'camera-module/cameras/ui/cameras-page/icons/AddCameraIcon'
import CButtonIcon from 'storybook-component-module/src/components/buttons/CButtonIcon'
import LoadingCamera from 'camera-module/cameras/ui/cameras-page/components/LoadingCamera'
import NoCameraTile from 'camera-module/cameras/ui/cameras-page/components/NoCameraTile'
import ActivateCameraButton from 'camera-module/cameras/ui/cameras-page/components/ActivateCameraButton'
import useUser from 'user-module/user/core/useUser'
import CameraSearch from 'camera-module/cameras/ui/cameras-page/CameraSearch'
import ConsentFormDialog from 'shared-module/cameras-module/ConsentFormDialog'
import SearchIcon from 'vosker/src/assets/icons/SearchIcon'
import { Mixpanel } from 'vosker/src/Mixpanel'
import { ConsentProvinceSelected } from 'vosker/src/consent-module/MarketingConsentProvince'
import MarketingProvinceForm from 'vosker/src/consent-module/MarketingProvinceForm'
import MarketingConsentSubscribe from 'vosker/src/consent-module/MarketingConsentSubscribe'
import userConsentActions from 'vosker/src/consent-module/core/userConsentActions'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '150px',
  },
  appbar: {
    background: theme.palette.background.default,
    justifyContent: 'space-between',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem !important',
      marginLeft: '-0.5rem',
      paddingTop: '0.2rem',
    },
  },
  icon: {
    width: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.1rem',
    },
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0.75rem 2rem 1.5rem',
    background: 'transparent',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  addButtonButton: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    padding: '0.75rem 0',
    color: 'none',
    background: 'none',
    paddingLeft: 16,
    '&:hover': {
      color: 'none',
      background: 'none',
    },
  },
  addButtonIcon: {
    width: 40,
    height: 40,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  noContent: {
    color: theme.palette.text.primary,
    fontFamily: 'Exo 2',
    fontSize: '15px',
    lineHeight: '21px',
    fontWeight: 600,
    alignSelf: 'center',
    textAlign: 'center',
    margin: '0 auto',
  },
  search: {
    color: theme.palette.text.secondary,
    fontFamily: 'Exo 2',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    alignSelf: 'center',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
    },

  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    margin: '1.25rem auto',
    padding: '0.75rem 0 1.5rem 0',
  },
  searchIcon: {
    width: '21px',
    height: '21px',
    marginTop: '12px',
    marginBottom: '7px',
  },
}))

const CamerasPage = ({ NewCameraForm, addCamera = false }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useUser()
  const cameras = useReloadCameras()
  const camerasActions = useCamerasActions()
  const cameraPlansActions = useCameraPlansActions()
  const location = useLocation()

  const queryParams = useQueryParams()
  const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const { cameraHasFreeTrial, openFreeTrialActivationModal } = useFreeTrialActivationModal()

  const { consent } = user
  const [isOpenConsentDialog, setIsOpenConsentDialog] = useState(false)
  const [isOpenAddCameraDialog, setIsOpenAddCameraDialog] = useState(addCamera)
  const [isSearchFieldExpanded, setIsSearchFieldExpanded] = useState(false)
  const [eventTracked, setEventTracked] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [isOpenProvinceSelectionDialog, setIsOpenProvinceSelectionDialog] = useState(false)
  const [isOpenProvinceSelectedDialog, setIsOpenProvinceSelectedDialog] = useState(false)
  const [userConsent, setUserConsent] = useState(false)
  const [territoryRequired, setTerritoryRequired] = useState(false)
  const [activateConsent, setActivateConsent] = useState(false)
  const [canShowMarketingConsentSubscribe, setCanShowMarketingConsentSubscribe] = useState(false)

  const marketingConsentEnabled = appConfig.marketingConsent.switch === switches.on

  const pageTitle = queryParams.transmission_plan
    ? t('app:purchasing_flow.transmission_plan.select_device')
    : t('menu.cameras')

  useEffect(() => {
    if (user && user.marketingEmail === undefined) {
      const fetchUserConsent = async () => {
        try {
          const result = await userConsentActions.fetchUserConsent(user.id)
          if (result) {
            setUserConsent(result.requiresConsent)
            setTerritoryRequired(result.isTerritoryRequired)
          }
        } catch (err) {
          console.error(err.message)
        }
      }

      fetchUserConsent()
    }
  }, [user])

  useEffect(() => {
    if (territoryRequired && userConsent) {
      localStorage.setItem(`delayConsent_${user.id}`, true)
    }
    const delayConsent = localStorage.getItem(`delayConsent_${user.id}`)
    const count = parseInt(localStorage.getItem(`loginAccessCount_${user.id}`), 10) || 0

    if (
      (count === 2 && userConsent && delayConsent) ||
      (count === 1 && userConsent && !delayConsent) ||
      localStorage.getItem(`reminderCount_${user.id}`)
    ) {
      setActivateConsent(true)
    } else {
      setActivateConsent(false)
    }
  }, [user, territoryRequired, userConsent])

  useEffect(() => {
    if (activateConsent && !isOpenProvinceSelectedDialog && userConsent && marketingConsentEnabled && !territoryRequired) {
      setCanShowMarketingConsentSubscribe(true)
    } else {
      setCanShowMarketingConsentSubscribe(false)
    }
  }, [activateConsent, isOpenProvinceSelectedDialog, marketingConsentEnabled, territoryRequired, userConsent])

  useEffect(() => {
    if (isOpenAddCameraDialog && !addCamera) {
      history.push('/add-camera')
    }
    if (!isOpenAddCameraDialog && addCamera) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddCameraDialog, addCamera])

  useEffect(() => {
    dispatch(cameraActions.clear())
    dispatch(cameraPlansActions.reset())
    if (!eventTracked) {
      setEventTracked(true)
      Mixpanel.identify(user.id)
      Mixpanel.track('Page Viewed', { 'Page Name': 'Home Page' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!consent) setIsOpenConsentDialog(true)
  }, [consent])

  useEffect(() => {
    if (territoryRequired && location.state?.showProvinceDialog) {
      const timer = setTimeout(() => setIsOpenProvinceSelectionDialog(true), 1000)
      return () => clearTimeout(timer)
    }
  }, [user, location, territoryRequired])

  const onAddCameraSuccess = (message, camera) => {
    dispatch(messageActions.showMessage(message))
    camerasActions.get()
    if (cameraHasFreeTrial(camera)) {
      openFreeTrialActivationModal(camera)
    }
  }

  const toggleAddCamera = () => setIsOpenAddCameraDialog(!isOpenAddCameraDialog)
  const filteredCameras = cameras?.filter(camera => camera.config.name.toLowerCase().includes(searchInput.toLowerCase()))

  const handleConsentSelectedDialogClose = () => {
    setIsOpenProvinceSelectedDialog(false)
    history.replace({ ...location, state: { ...location.state, showProvinceDialog: false } })
  }

  const renderCameras = () => {
    if (!cameras) {
      return <LoadingCamera />
    }

    if (cameras.length === 0) {
      return <NoCameraTile />
    }

    return (
      <Paper className={classes.paper} elevation={0} style={{ whiteSpace: 'nowrap' }}>
        { searchInput
          ? (
              filteredCameras.length > 0
                ? filteredCameras.map((camera) => <CameraTile isNewUi key={camera.id} camera={camera} />)
                : (
                  <Paper elevation={0} className={classes.paperContainer}>
                    <SearchIcon className={classes.searchIcon} />
                    <Typography className={classes.noContent}>{ t('search.no_camera') } </Typography>
                    <Typography className={classes.search}> { t('search.try') }</Typography>
                  </Paper>

                  )
            )
          : cameras.map((camera) => <CameraTile isNewUi key={camera.id} camera={camera} />) }

        { !searchInput && (
          isSmallDisplay
            ? <ActivateCameraButton toggleAddCamera={toggleAddCamera} displayFullWidth />
            : <ActivateCameraTile toggleAddCamera={toggleAddCamera} displayText={false} isNewUi />
        ) }
      </Paper>
    )
  }

  return (
    <div className={classes.root}>
      <CAppBar
        variant="h1"
        Icon={isSmallDisplay && isSearchFieldExpanded ? undefined : CameraIcon}
        title={isSmallDisplay && isSearchFieldExpanded ? undefined : pageTitle}
        className={classes.appbar}
        titleClassName={classes.title}
        iconClassName={classes.icon}
        showDivider
      >
        <Box component="div" className={classes.buttonContainer}>
          <CameraSearch
            id="camera-search-input"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            isSearchFieldExpanded={isSearchFieldExpanded}
            setIsSearchFieldExpanded={setIsSearchFieldExpanded}
          />

          { isSmallDisplay && !isSearchFieldExpanded && (
            <CButtonIcon onClick={toggleAddCamera} className={classes.addButtonButton}>
              <AddCameraIcon className={classes.addButtonIcon} color={theme.palette.secondary.main} />
            </CButtonIcon>
          ) }
          { !isSmallDisplay && (
            <ActivateCameraButton toggleAddCamera={toggleAddCamera} displayFullWidth={!isSmallDisplay} />
          ) }
        </Box>

      </CAppBar>

      { renderCameras() }
      { isOpenConsentDialog && marketingConsentEnabled && <ConsentFormDialog isNewUi onClose={() => setIsOpenConsentDialog(false)} /> }
      { addCamera && <NewCameraForm onClose={toggleAddCamera} onAddCameraSuccess={onAddCameraSuccess} /> }

      { marketingConsentEnabled && (
        <MarketingProvinceForm
          isOpenProvinceSelectionDialog={isOpenProvinceSelectionDialog}
          setIsOpenProvinceSelectionDialog={setIsOpenProvinceSelectionDialog}
          setIsOpenProvinceSelectedDialog={setIsOpenProvinceSelectedDialog}
        />
      ) }
      { isOpenProvinceSelectedDialog && marketingConsentEnabled && <ConsentProvinceSelected open={isOpenProvinceSelectedDialog} onClose={handleConsentSelectedDialogClose} /> }
      { canShowMarketingConsentSubscribe && <MarketingConsentSubscribe open={userConsent} /> }
    </div>
  )
}

export default CamerasPage
