import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const getUserConsent = userId => restapi
  .get(`api/v3/user/consent/${userId}`)
  .then(res => res.data)
  .catch(handleError)

const userConsentApi = { getUserConsent }
export default userConsentApi
