import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { useECommerceProducts } from 'vosker/src/ecommerce-module/core/eCommerce.hook'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useStyles = makeStyles(theme => ({
  caption: {
    display: 'flex',
    textAlign: 'justify',
    flexDirection: 'column',
    padding: '40px 18px 0 18px',
    [theme.breakpoints.only('md')]: {
      padding: '24px 16px 0 16px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '16px 16px 0 16px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  typography: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.625rem',
    },
  },
}))

const PlanFinePrints = ({ plans, isOnTrial }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const eCommerceProducts = useECommerceProducts()
  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on

  const addonsAreLoaded = eCommerceEnabled
    ? eCommerceProducts?.addons.length !== 0 && eCommerceProducts.addons.map(addon => addon.eligibleCameras?.includes(camera.status.model)).includes(true)
    : plans?.addOns.length !== 0

  const hasInstantModeCapabilities = camera && (camera.status.capability?.instantMode || camera?.config?.operationMode === 'instant')

  if (!eCommerceEnabled) {
    if (addonsAreLoaded) {
      return (
        <Box className={classes.caption}>
          <Box pt={0.5}>
            <Typography variant="caption" display="inline" className={classes.typography}>
              { t('app:plan:fine_prints_one') }
              { ' ' }
              { t('app:plan:fine_prints_two') }
              { ' ' }
              { isOnTrial && t('app:plan:fine_prints_four') }
            </Typography>
          </Box>

          <Box pt={2}>
            <Typography variant="caption" className={classes.typography}>
              { t('app:plan:fine_prints_link') }
            </Typography>
          </Box>

          <Box pt={0.25}>
            <Typography variant="caption" className={classes.typography}>
              { t('app:plan:fine_prints_note') }
            </Typography>
          </Box>
        </Box>
      )
    }
  }

  return eCommerceEnabled && (
    <Box className={classes.caption}>
      { addonsAreLoaded && (
        <Box pt={0.5}>
          <Typography variant="caption" display="inline" className={classes.typography}>
            { t('app:plan:fine_prints_one') }
            { ' ' }
            { t('app:plan:fine_prints_two') }
            { ' ' }
            { isOnTrial && t('app:plan:fine_prints_four') }
          </Typography>
        </Box>
      ) }

      { hasInstantModeCapabilities && (
        <Box pt={0.5}>
          <Typography variant="caption" display="inline" className={classes.typography}>
            { t('app:plan:fine_prints_preview') }
            { ' ' }
            { t('app:plan:fine_prints_plan_inclusion') }
          </Typography>
        </Box>
      ) }

      <Box pt={2}>
        <Typography variant="caption" className={classes.typography}>
          { t('app:plan:fine_prints_link') }
        </Typography>
      </Box>

      <Box pt={0.25}>
        <Typography variant="caption" className={classes.typography}>
          { t('app:plan:fine_prints_note') }
        </Typography>
      </Box>
    </Box>
  )
}

export default PlanFinePrints
