import { useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import usePhotoViewerNavigation from 'photo-module/photo/core/usePhotoViewerNavigation'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useKeyPress from 'shared-module/components/key-press/useKeyPress'
import VoskerButtonWrapper from 'vosker/src/photos-module/photo/ui/actions-bar/VoskerButtonWrapper'
import PreviousArrowIcon from 'vosker/src/assets/icons/viewer/PreviousArrowIcon'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  previous: {
    display: 'flex',
    position: 'absolute',
    height: 'calc(100vh - 128px)',
    width: '15vw',
    alignItems: 'center',
    transition: '.2s all',
    cursor: 'default',
    opacity: 0,
    left: '20px',
    marginLeft: '-20px',
    paddingLeft: '20px',
    justifyContent: 'flex-start',
    zIndex: 1,
    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.down('lg')]: {
      width: '5vw',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
    },
  },
  icon: {
    marginLeft: 2,
  },
}))

const PreviousButton = () => {
  const classes = useStyles()
  const theme = useTheme()
  const arrowLeftPressed = useKeyPress('ArrowLeft')
  const photo = useSelectedPhoto()
  const { previousPhoto, switchPhoto } = usePhotoViewerNavigation(photo?.id)

  const previous = () => switchPhoto(previousPhoto)

  useEffect(() => {
    if (arrowLeftPressed && previousPhoto) { previous() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowLeftPressed])

  return previousPhoto
    ? (
      <Grid item data-testid="PreviousButton" onClick={previous} className={classes.previous}>
        <VoskerButtonWrapper>
          <PreviousArrowIcon color={theme.palette.basic?.white} className={classes.icon} fontSize="large" />
        </VoskerButtonWrapper>
      </Grid>
      )
    : <div data-testid="NoPreviousButton" />
}

export default PreviousButton
