import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'

import downloadStatus from 'photo-module/download/downloadStatus'
import FullHdVideoShadowIcon from 'assets/icons/video/FullHdVideoShadowIcon'
import EventPendingIcon from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/icons/EventPendingIcon'
import EventErrorIcon from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/icons/EventErrorIcon'

const useStyles = makeStyles(theme => ({
  statusIcon: {
    width: 'auto',
    height: '2.25rem',
    marginTop: '0.25rem',
    marginLeft: '0.25rem',
    marginRight: '-0.25rem',
  },
  statusIconColor: {
    width: 'auto',
    height: '1.25rem !important',
    marginRight: '0.25rem',
    marginLeft: '0.25rem',
    fill: theme.palette.basic?.white + ' !important',
  },
}))

const VideoStatusPending = () => {
  const classes = useStyles()

  return <EventPendingIcon className={classes.statusIcon} />
}

const VideoStatusError = () => {
  const classes = useStyles()

  return <EventErrorIcon className={classes.statusIcon} />
}

const VideoStatusAvailable = () => {
  const classes = useStyles()

  return (
    <FullHdVideoShadowIcon className={clsx(classes.statusIcon, classes.statusIconColor)} />
  )
}

const iconByState = {
  [downloadStatus.pending]: VideoStatusPending,
  [downloadStatus.available]: VideoStatusAvailable,
  [downloadStatus.error]: VideoStatusError,
}

const FullHdStatusOverlay = ({ photo }) => {
  // TODO: Ultimately remove sdVideoStatus
  const Icon = iconByState[photo.sdVideoStatus] || iconByState[photo.hdVideoStatus] || iconByState[photo.hdStatus]

  return Icon
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ? <Icon />
    : null
}

export default FullHdStatusOverlay
