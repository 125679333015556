import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const SadFaceIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} viewBox="0 0 161 160" style={{ width: 161, height: 160 }} data-testid="sad-face-icon">
      <path fillRule="evenodd" clipRule="evenodd" d="M80.0972 123.858C109.053 123.858 132.526 100.385 132.526 71.4292C132.526 42.4734 109.053 19 80.0972 19C51.1413 19 27.668 42.4734 27.668 71.4292C27.668 100.385 51.1413 123.858 80.0972 123.858Z" fill={theme.palette?.icon?.icon} />
      <path fillRule="evenodd" clipRule="evenodd" d="M80.0972 122.525C108.317 122.525 131.193 99.6487 131.193 71.4292C131.193 43.2098 108.317 20.3333 80.0972 20.3333C51.8777 20.3333 29.0013 43.2098 29.0013 71.4292C29.0013 99.6487 51.8777 122.525 80.0972 122.525ZM132.526 71.4292C132.526 100.385 109.053 123.858 80.0972 123.858C51.1413 123.858 27.668 100.385 27.668 71.4292C27.668 42.4734 51.1413 19 80.0972 19C109.053 19 132.526 42.4734 132.526 71.4292Z" fill={theme.palette?.text?.contrastText} />
      <path fillRule="evenodd" clipRule="evenodd" d="M106.784 120.022C98.8653 124.38 89.7668 126.858 80.0972 126.858C49.4845 126.858 24.668 102.042 24.668 71.4292C24.668 40.8165 49.4845 16 80.0972 16C110.71 16 135.526 40.8165 135.526 71.4292C135.526 84.4557 131.029 96.4388 123.506 105.901C124.07 107.401 124.453 109.007 124.453 110.652C124.453 116.471 121.107 120.367 116.825 121.706C113.526 122.736 109.7 122.217 106.784 120.022ZM107.226 116.303C99.3115 121.098 90.0267 123.858 80.0972 123.858C51.1413 123.858 27.668 100.385 27.668 71.4292C27.668 42.4734 51.1413 19 80.0972 19C109.053 19 132.526 42.4734 132.526 71.4292C132.526 84.3908 127.823 96.2539 120.03 105.405C120.891 107.191 121.453 108.965 121.453 110.652C121.453 119.673 111.467 121.588 107.226 116.303Z" fill={theme.palette?.checkbox?.primary} />
      <path fillRule="evenodd" clipRule="evenodd" d="M57.8648 81.3052C55.6189 81.6557 53.5284 82.5079 51.7949 83.5026C49.8787 84.602 47.3436 84.5352 45.8382 82.9184L43.3117 80.2047C46.0737 77.6332 50.8959 74.5918 56.6763 73.6897C62.6769 72.7532 69.5135 74.1739 75.6399 80.3002L73.0181 82.922C71.456 84.4841 68.9544 84.4177 67.0882 83.2355C63.8557 81.1878 60.6875 80.8647 57.8648 81.3052Z" fill={theme.palette?.text?.contrastText} />
      <path fillRule="evenodd" clipRule="evenodd" d="M78.2072 104.856C76.3128 105.153 74.5402 105.846 73.0364 106.671C71.0919 107.738 68.5419 107.675 67.0351 106.047L65.0023 103.851C67.5084 101.505 71.8839 98.7292 77.1288 97.906C82.5735 97.0513 88.7769 98.3478 94.3357 103.939L92.2269 106.059C90.663 107.632 88.1516 107.554 86.2484 106.415C83.4292 104.727 80.6729 104.469 78.2072 104.856Z" fill={theme.palette?.text?.contrastText} />
      <path fillRule="evenodd" clipRule="evenodd" d="M99.7867 81.3052C97.5408 81.6557 95.4503 82.5079 93.7167 83.5026C91.8006 84.602 89.2654 84.5352 87.7601 82.9184L85.2336 80.2047C87.9955 77.6332 92.8177 74.5918 98.5981 73.6897C104.599 72.7532 111.435 74.1739 117.562 80.3002L114.94 82.922C113.378 84.4841 110.876 84.4177 109.01 83.2355C105.778 81.1878 102.609 80.8647 99.7867 81.3052Z" fill={theme.palette?.text?.contrastText} />
      <path fillRule="evenodd" clipRule="evenodd" d="M113.198 94.8525C110.378 99.4716 105.355 105.476 105.355 110.458C105.355 122.105 121.452 122.105 121.452 110.652C121.452 105.476 116.152 99.4716 113.198 94.8525Z" fill={theme.palette?.icon?.iconBlue} />
      <path fillRule="evenodd" clipRule="evenodd" d="M109.365 103.201C107.769 105.882 106.689 108.357 106.689 110.458C106.689 113.044 107.573 114.87 108.797 116.054C110.038 117.253 111.712 117.874 113.412 117.884C115.112 117.894 116.783 117.294 118.02 116.123C119.239 114.969 120.118 113.183 120.118 110.652C120.118 108.467 118.979 105.948 117.3 103.247C116.476 101.921 115.557 100.604 114.644 99.3105C114.553 99.1808 114.461 99.0511 114.37 98.9216C113.982 98.3721 113.596 97.8257 113.224 97.2884C112.87 97.8251 112.503 98.3707 112.134 98.9191C112.053 99.0402 111.971 99.1615 111.889 99.2829C111.021 100.574 110.148 101.886 109.365 103.201ZM112.414 96.0924C111.975 96.7669 111.506 97.4641 111.026 98.1777C108.36 102.14 105.355 106.605 105.355 110.458C105.355 122.105 121.452 122.105 121.452 110.652C121.452 106.641 118.268 102.131 115.46 98.1547C114.957 97.4425 114.467 96.7473 114.008 96.0752C113.724 95.6583 113.452 95.2503 113.198 94.8525C112.952 95.2558 112.689 95.6696 112.414 96.0924Z" fill={theme.palette?.text?.contrastText} />
      <path d="M124.335 140.999C124.335 142.84 104.486 144.333 80.0013 144.333C55.5167 144.333 35.668 142.84 35.668 140.999C35.668 139.158 55.5167 137.666 80.0013 137.666C104.486 137.666 124.335 139.158 124.335 140.999Z" fill={theme.palette?.icon?.iconShadow} fillOpacity="0.4" />
      <path d="M124.334 141C124.334 142.105 119.409 143 113.334 143C107.258 143 102.334 142.105 102.334 141C102.334 139.895 107.258 139 113.334 139C119.409 139 124.334 139.895 124.334 141Z" fill={theme.palette?.icon?.iconShadow} />
    </SvgIcon>

  )
}

export default SadFaceIcon
