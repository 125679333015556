import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import { makeStyles } from '@material-ui/core/styles'
import Head from 'shared-module/new-components/head/Head'
import ForgotPasswordLink from 'user-module/user/ui/login/components/ForgotPasswordLink'
import useUser from 'user-module/user/core/useUser'
import userState from 'user-module/user/core/userState'
import LoginPageForm from 'user-module/user/ui/login/components/LoginPageForm'
import useLoginRedirect from 'user-module/user/core/useLoginRedirect'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import LoginRegisterWrapper from 'user-module/user/ui/LoginRegisterWrapper'
import CTitle from 'storybook-component-module/src/components/typographies/CTitle'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.status?.info,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const LoginPage = ({ isNewUi = false }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const user = useUser()
  const history = useHistory()
  const redirection = useLoginRedirect()
  const location = useLocation()

  useEffect(() => {
    if (userState.isReady(user)) {
      i18n.changeLanguage(user.language)

      const loginAccessCountKey = `loginAccessCount_${user.id}`
      const accessLimitExceededKey = `accessLimitExceeded_${user.id}`

      const accessLimitExceeded = localStorage.getItem(accessLimitExceededKey) === 'true'

      if (!accessLimitExceeded) {
        const loginAccessCount = parseInt(localStorage.getItem(loginAccessCountKey), 10) || 0

        if (loginAccessCount <= 2) {
          const newCount = loginAccessCount + 1
          localStorage.setItem(loginAccessCountKey, newCount)

          if (newCount > 2) {
            localStorage.removeItem(loginAccessCountKey)
            localStorage.setItem(accessLimitExceededKey, 'true')
          }
        }
      }

      redirection.redirect()
      history.push({
        pathname: '/cameras',
        state: { showProvinceDialog: true },
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, history])

  return (
    <LoginRegisterWrapper>
      <Head title={t('app:login.head.title')} description={t('app:login.head.description')} />
      <CTitle marginBottom={3}>{ t('common:login.title') }</CTitle>
      <Box component="div" mb={1}>
        { t('common:login.sign_up.title') }
        <Box ml="5px" component="span" display="inline-block">
          <ForwardBackLink id="lnk_register" pathname="/register" underline="none" search={location.search} className={isNewUi ? classes.link : ''}>
            { t('register.login.create') }
          </ForwardBackLink>
        </Box>
      </Box>
      <FormDataContext>
        <LoginPageForm isNewUi={isNewUi} />
      </FormDataContext>
      <ForgotPasswordLink />
    </LoginRegisterWrapper>
  )
}

export default LoginPage
