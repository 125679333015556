import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const PaperPlaneIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} viewBox="0 0 161 160" style={{ width: 161, height: 160 }} data-testid="paper-plane-icon">
      <path fillRule="evenodd" clipRule="evenodd" d="M145.032 19.7901C145.032 19.7901 153.71 81.2974 147.914 97.6124C142.51 112.981 118.966 135.789 102.593 146.884L95.6811 151.409L95.6837 151.419C90.8233 154.551 85.6964 157.416 80.5223 160.001C74.1779 156.809 67.9242 153.258 61.988 149.363C45.877 139.477 18.9269 114.254 13.1309 97.7756C7.33483 81.2975 16.0125 19.7901 16.0125 19.7901L80.5221 4.92383L145.032 19.7901Z" fill={theme.palette?.icon?.iconConsent} />
      <path fillRule="evenodd" clipRule="evenodd" d="M116.557 42.375L118.604 36.4912L113.025 39.1595C112.979 39.1796 112.934 39.2011 112.89 39.224L36.7203 75.6528L61.0484 95.1153V117.179L73.3059 104.921L90.1225 118.375L116.537 42.4327C116.544 42.4135 116.551 42.3943 116.557 42.375Z" fill={theme.palette?.icon?.icon} />
      <path fillRule="evenodd" clipRule="evenodd" d="M121.495 33.2617C122.798 34.4277 123.273 36.2632 122.699 37.9152L120.658 43.7809C120.648 43.8123 120.637 43.8436 120.626 43.8749L94.2175 119.799C93.7553 121.127 92.6768 122.149 91.3249 122.539C89.9731 122.928 88.5162 122.638 87.4176 121.759L73.6297 110.728L64.1147 120.243C62.8754 121.483 61.0115 121.854 59.3923 121.183C57.773 120.512 56.7172 118.932 56.7172 117.179V97.1984L34.0154 79.037C32.8509 78.1054 32.2473 76.6405 32.4174 75.1589C32.5875 73.6774 33.5074 72.3874 34.8528 71.744L110.979 35.3357C111.052 35.2991 111.126 35.2639 111.2 35.2301L116.736 32.5824C118.314 31.8277 120.191 32.0957 121.495 33.2617ZM61.0505 95.1157V117.179L73.3081 104.922L90.1246 118.375L116.539 42.433C116.546 42.4139 116.553 42.3947 116.559 42.3755L118.606 36.4916L113.027 39.1599C112.981 39.18 112.936 39.2015 112.892 39.2244L36.7224 75.6532L61.0505 95.1157Z" fill={theme.palette?.checkbox?.primary} />
      <path fillRule="evenodd" clipRule="evenodd" d="M119.495 35.4977C119.896 35.8565 120.042 36.4213 119.865 36.9296L117.821 42.8067C117.813 42.8307 117.805 42.8544 117.796 42.8779L91.3839 118.813C91.2417 119.222 90.9099 119.536 90.4939 119.656C90.078 119.776 89.6297 119.687 89.2917 119.416L73.407 106.708L61.9933 118.122C61.612 118.503 61.0385 118.617 60.5403 118.411C60.042 118.205 59.7172 117.719 59.7172 117.179V95.7565L35.8895 76.6943C35.5312 76.4077 35.3454 75.9569 35.3978 75.5011C35.4501 75.0452 35.7332 74.6483 36.1471 74.4503L112.301 38.029C112.356 38.0011 112.411 37.9746 112.467 37.9495L118.031 35.2887C118.516 35.0565 119.094 35.139 119.495 35.4977ZM113.027 39.1598C112.981 39.18 112.936 39.2015 112.892 39.2244L36.7224 75.6532L60.9359 95.024L117.276 37.1276L113.027 39.1598ZM61.0505 96.8337V117.179L72.3536 105.876L61.0505 96.8337ZM73.3022 104.928L73.3081 104.922L90.1246 118.375L116.539 42.433C116.546 42.4138 116.553 42.3946 116.559 42.3753L117.966 38.3299L61.9762 95.8668L73.3022 104.928Z" fill={theme.palette?.text?.contrastText} />
      <path d="M81.3946 0.398438L77.2561 26.2735L86.9212 27.8194L91.0598 1.94432L81.3946 0.398438Z" fill={theme.palette?.checkbox?.primary} />
      <path d="M69.8199 30.2066L58.3686 6.63716L49.5647 10.9145L61.0159 34.484L69.8199 30.2066Z" fill={theme.palette?.checkbox?.primary} />
      <path d="M53.3278 37.8987L30.4286 25.1599L25.6703 33.7134L48.5695 46.4522L53.3278 37.8987Z" fill={theme.palette?.checkbox?.primary} />
    </SvgIcon>

  )
}

export default PaperPlaneIcon
