import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const ProTipsIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} viewBox="0 0 28 29" style={{ width: 28, height: 29 }} data-testid="pro-tips-icon">
      <circle cx="13.9971" cy="14.4974" r="14.0023" fill={theme.palette?.status?.warning} />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.721 8.25117C11.6958 7.53411 12.8737 7.14646 14.0838 7.14453C15.2947 7.1446 16.4739 7.53091 17.4501 8.2473C18.4263 8.96368 19.1485 9.97278 19.5117 11.1279C19.8749 12.2829 19.8602 13.5238 19.4697 14.6699C19.0792 15.816 18.3333 16.8077 17.3404 17.5008C17.2805 17.5394 17.1478 17.6301 17.1478 17.6301V19.0263C17.1478 19.233 16.9804 19.4004 16.7738 19.4004H16.2724H11.8953H11.3939C11.1873 19.4004 11.0199 19.233 11.0199 19.0263V17.8597C11.021 17.7894 11.0199 17.6301 11.0199 17.6301C11.0199 17.6301 10.8937 17.541 10.836 17.5008C9.84374 16.8082 9.0981 15.8173 8.7074 14.672C8.3167 13.5267 8.30128 12.2867 8.66339 11.132C9.02549 9.97738 9.74627 8.96824 10.721 8.25117ZM12.4471 20.7126H15.7308C15.7478 20.7116 15.788 20.711 15.8406 20.7108C16.1044 20.7095 16.2845 20.9781 16.1806 21.2206C16.1546 21.2812 16.134 21.3273 16.1249 21.343L15.8359 21.9297C15.6903 22.2228 15.4654 22.4693 15.1868 22.6411C14.9082 22.8129 14.5298 22.9017 14.2597 22.9017H13.9095C13.634 22.9017 13.271 22.8057 12.9975 22.6342C12.724 22.4626 12.5032 22.2188 12.3596 21.9297L12.0706 21.343C12.0621 21.3283 12.0427 21.287 12.0179 21.2321C11.9074 20.9874 12.0895 20.7121 12.3579 20.7124L12.4471 20.7126Z" fill={theme.palette?.icon?.iconPromo} />
    </SvgIcon>

  )
}

export default ProTipsIcon
