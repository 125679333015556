import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import PlayIcon from 'assets/icons/video/PlayIcon'
import videoState from 'video-module/video-clip/core/videoState'

const useStyles = makeStyles(theme => ({
  item: {
    position: 'absolute',
    left: '12px',
    bottom: '5px',
  },
  playIconWrapper: {
    width: '24px',
    height: '24px',
    borderRadius: '3rem',
    background: alpha(theme.palette.background?.shadow, 0.8),
    backdropFilter: 'blur(0.2rem)',
    '&:hover': {
      background: theme.palette.background?.shadow,
    },
  },
  playIcon: {
    width: '22px',
    opacity: 1,
    marginLeft: '0.125rem',
    fill: theme.palette.basic?.white + ' !important',
  },
}))

const VideoPlayOverlay = ({ photo }) => {
  const classes = useStyles()

  const videoOrStream = videoState.isAVideo(photo) || videoState.isAStream(photo) || videoState.hasPreview(photo)

  return videoOrStream
    ? (
      <Grid item className={classes.item}>
        <IconButton size="small" className={classes.playIconWrapper}>
          <PlayIcon className={classes.playIcon} />
        </IconButton>
      </Grid>
      )
    : null
}

export default VideoPlayOverlay
