import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const NewProductsIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} viewBox="0 0 28 29" style={{ width: 28, height: 29 }} data-testid="new-products-icon">
      <circle cx="14" cy="14.5" r="14" fill={theme.palette?.status?.warning} />
      <path d="M6.99707 7.68403C7.0928 7.64154 7.19539 7.61983 7.29894 7.62017C7.50788 7.62017 7.70827 7.70952 7.85601 7.86856C8.00375 8.02761 8.08676 8.24331 8.08676 8.46823V18.6185H8.00676C8.00676 18.6185 6.51112 18.6185 6.51112 17.0085V8.46823C6.5108 8.35676 6.53096 8.24634 6.57044 8.14329C6.60992 8.04024 6.66793 7.94662 6.74115 7.8678C6.81437 7.78898 6.90135 7.72653 6.99707 7.68403Z" fill={theme.palette?.icon?.iconPromo} />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.5016 10.9739L20.6309 11.8933C20.8928 12.0091 21.1165 12.2065 21.2734 12.4602C21.4302 12.7139 21.513 13.0123 21.5111 13.317V18.9201C21.5111 19.2254 21.4261 19.5237 21.2671 19.7766C21.1081 20.0294 20.8824 20.2252 20.6191 20.3387L18.4898 21.2607C18.3218 21.3324 18.1428 21.3695 17.9622 21.3702H13.1617C12.9809 21.3698 12.8019 21.3326 12.6341 21.2607L10.5047 20.3387C10.2414 20.2252 10.0156 20.0294 9.85667 19.7766C9.69769 19.5237 9.61271 19.2254 9.6127 18.9201V13.317C9.61232 13.0099 9.69791 12.7098 9.85828 12.4559C10.0186 12.2019 10.2464 12.0059 10.5118 11.8933L12.6411 10.9739C12.809 10.9019 12.9881 10.8648 13.1688 10.8644H17.974C18.1547 10.865 18.3336 10.9021 18.5016 10.9739ZM18.9163 14.8478H16.6236V17.3157H18.9163V14.8478Z" fill={theme.palette?.icon?.iconPromo} />
    </SvgIcon>

  )
}

export default NewProductsIcon
