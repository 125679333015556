import { useMemo } from 'react'
import useUser from 'user-module/user/core/useUser'
import useLanguage from 'shared-module/components/language/useLanguage'

import { FREQUENCY } from './discount.types'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { formatDiscount, getRawDiscount } from './discount.selectors'

import { useCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import { useECommerceProducts } from 'vosker/src/ecommerce-module/core/eCommerce.hook'

export const useDiscount = (cameraPlan, frequency = FREQUENCY.yearly) => {
  const language = useLanguage()
  const currency = useUser()?.currency || 'USD'

  return formatDiscount(getRawDiscount(cameraPlan, frequency), currency, language)
}

export const useBestYearlyDiscount = () => {
  let bestDiscount = 0
  const language = useLanguage()
  const currency = useUser()?.currency || 'USD'
  const plans = useCameraPlans()
  const eCommerceProducts = useECommerceProducts()

  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const cameraPlans = useMemo(() => !eCommerceEnabled && plans.plans.filter(plan => plan.id !== apiCameraPlans.empty), [plans])

  cameraPlans && cameraPlans.forEach(cameraPlan => {
    const discount = getRawDiscount(cameraPlan, FREQUENCY.yearly)
    if (discount?.value > (bestDiscount?.value || 0)) {
      bestDiscount = discount
    }
  })

  const compareDiscount = () => {
    const allDiscounts = eCommerceProducts.plans && eCommerceProducts.plans
      .map(plan => plan.discount)
      .filter(discount => discount !== false)
      .every((val, i, arr) => val === arr[0])

    return allDiscounts && eCommerceProducts.plans.findLast(plan => plan.discount)?.discount
  }

  return eCommerceEnabled
    ? compareDiscount()
    : formatDiscount(bestDiscount, currency, language)
}
