import restapi from 'shared-module/api/restapi'
import commerceSelector, { eCommerceTokenID } from 'vosker/src/ecommerce-module/core/eCommerce.selectors'
import eCommerceBuilder from 'vosker/src/ecommerce-module/api/eCommerce.builder'

const authenticate = () => restapi
  .post('/api/v3/commerce/user/token')
  .then(response => response.data)
  .catch(error => error)

const fetchPlans = () => restapi
  .get(
    `/api/v3/commerce/products?ids=${commerceSelector.salesForcePlansIds}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
  .then(response => response.data.data.map(plan => eCommerceBuilder.buildECommercePlans(plan)))
  .catch(error => error)

const fetchAddons = () => restapi
  .get(
    `/api/v3/commerce/products?ids=${commerceSelector.salesForceAddonsIds}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
  .then(response => response.data.data.map(addon => eCommerceBuilder.buildECommerceAddons(addon)))
  .catch(error => error)

const createBasket = () => restapi
  .post(
    '/api/v3/commerce/baskets', {}, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
  .then(response => response.data)
  .catch(error => {
    if (error.response && error.response.data && error.response.data.basketIds) {
      const basketId = error.response.data.basketIds
      localStorage.setItem('basketId', basketId)
      return getBasket(basketId)
    }
    console.error('Error creating a new basket:', error)
    throw error
  })

const getBasket = (basketId) => {
  return restapi
    .get(`/api/v3/commerce/baskets/${basketId}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching basket:', error)
      return error
    })
}

const deleteBasket = (basketId) => {
  return restapi
    .delete(`/api/v3/commerce/baskets/${basketId}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
    .then(response => response.data, localStorage.removeItem('basketId'))
    .catch(error => {
      console.error('Error deleting basket:', error)
      return error
    })
}

const deleteItemFromBasket = (basketId, itemId) => {
  return restapi
    .delete(`/api/v3/commerce/baskets/${basketId}/items/${itemId}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
    .then(response => response.data)
    .catch(error => {
      return error
    })
}

const addItemToBasket = (basketId, itemDataList) => {
  const token = localStorage.getItem(eCommerceTokenID)
  const requestBody = itemDataList.map(item => ({
    productId: item.productId,
    quantity: item.quantity || 1,
  }))

  return restapi
    .post(
      `/api/v3/commerce/baskets/${basketId}/items`,
      requestBody,
      {
        headers: { 'x-commerce-token': token },
      },
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error adding item to basket:', error)
      return error
    })
}

const eCommerceApi = { authenticate, fetchPlans, fetchAddons, createBasket, getBasket, deleteBasket, addItemToBasket, deleteItemFromBasket }
export default eCommerceApi
