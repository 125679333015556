import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import Backdrop from '@mui/material/Backdrop'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import ProvinceSelectedIcon from 'camera-module/cameras/ui/cameras-page/icons/ProvinceSelectedIcon'
import ProvinceSelectionIcon from 'camera-module/cameras/ui/cameras-page/icons/ProvinceSelectionIcon'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import ProfileTerritory from 'shared-module/user/ProfileTerritory'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
    padding: 20,
    borderRadius: 6,
    minHeight: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContent: {
    padding: '12px !important',
    textAlign: 'left',
    flexGrow: 1,
  },
  dialogAction: {
    padding: '12px !important',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  icon: {
    width: 139,
    height: 160,
  },
  title: {
    '&.MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.h6?.fontSize,
      fontWeight: 600,
      fontFamily: theme.typography.h6?.fontFamily,
    },
  },
  text: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.body1?.fontFamily,
    },
    textAlign: 'left',
  },
  select: {
    marginTop: 5,
    width: '100%',
    backgroundColor: theme.palette.background?.paper,
    borderColor: theme.palette.background?.light,
    '&.MuiInputBase-root': {
      color: 'theme.palette.text.secondary',
    },
  },
  confirmButton: {
    width: '40%',
    marginRight: 2,
  },
  dropDown: {
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: theme.palette.background?.paper,
    '&:focus': {
      backgroundColor: theme.palette.background?.dropdown,
    },
    '&:hover': {
      backgroundColor: theme.palette.background?.empty,
    },
  },
}))

const paperProps = theme => {
  return {
    style: {
      maxWidth: 410,
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
      borderRadius: 6,
    },
  }
}

const slotProps = theme => {
  return {
    backdrop: { sx: { background: theme.palette.background?.shadow + 'B8' } },
  }
}

const ConsentProvinceSelection = ({ open, onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const formik = useFormikContext()
  const inputClasses = VoskerInputStyles()

  const handleProvinceUpdate = () => formik.values.territory && formik.handleSubmit()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={paperProps(theme)}
      slots={{ backdrop: Backdrop }}
      slotProps={slotProps(theme)}
    >
      <Box className={classes.dialogWrapper}>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.iconWrapper}>
            <ProvinceSelectionIcon className={classes.icon} />
          </Box>

          <Typography className={classes.title} variant="h6">
            { t('app:marketing_consent.province_title') }
          </Typography>

          <Typography className={classes.text} variant="body1" sx={{ mt: 0.45, mb: 3 }}>
            { t('app:marketing_consent.province_text') }
          </Typography>
          <Box className={classes.select}>
            <ProfileTerritory
              required
              name="territory"
              form="mc-province-selection"
              formik={formik}
              label={t('app:marketing_consent.province_selection')}
              showErrorIcon
              className={inputClasses.input}
              menuClassName={classes.dropDown}
            />
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <VoskerPrimaryButton
            noWrap
            size="large"
            disabled={!formik.values.territory}
            className={classes.confirmButton}
            onClick={handleProvinceUpdate}
          >
            { t('app:marketing_consent.confirm_button') }
          </VoskerPrimaryButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

const ConsentProvinceSelected = ({ open, onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={paperProps(theme)}
      slots={{ backdrop: Backdrop }}
      slotProps={slotProps(theme)}
    >
      <Box className={classes.dialogWrapper}>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.iconWrapper}>
            <ProvinceSelectedIcon className={classes.icon} />
          </Box>
          <Typography className={classes.title} variant="h6">
            { t('app:marketing_consent.province_saved') }
          </Typography>
          <Typography className={classes.text} variant="body1" sx={{ mt: 0.45, mb: 1.5 }}>
            { t('app:marketing_consent.province_saved_text') }
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <VoskerPrimaryButton noWrap size="large" type="submit" className={classes.confirmButton} onClick={onClose} data-testid="confirm-button" path="/">
            { t('app:marketing_consent.gotit_button') }
          </VoskerPrimaryButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export { ConsentProvinceSelection, ConsentProvinceSelected }
