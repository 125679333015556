import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import jwtToken from 'shared-module/token/jwtToken'
import Spinner from 'shared-module/components/Spinner'
import useUserActions from 'user-module/user/core/useUserActions'

const AutoLogin = ({ children }) => {
  const location = useLocation()
  const userActions = useUserActions()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.has('t') ? params.get('t') : jwtToken.get()
    const refresh = jwtToken.getRefresh()

    if (token) {
      userActions.autologin(token, refresh).finally(() => setReady(true))
    } else {
      setReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return ready ? children : <Spinner />
}

export default AutoLogin
