import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const LightningIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} viewBox="0 0 28 29" style={{ width: 28, height: 29 }} data-testid="lightning-icon">
      <circle cx="13.9977" cy="14.4974" r="14.0023" fill={theme.palette?.status?.warning} />
      <g clipPath="url(#clip0_11706_31399)">
        <path d="M19.5492 12.6694C19.5215 12.6124 19.478 12.5645 19.424 12.5314C19.37 12.4982 19.3076 12.4812 19.2443 12.4823H14.1306L15.4263 6.66874C15.4501 6.5859 15.4429 6.49721 15.4058 6.41937C15.3688 6.34152 15.3047 6.27989 15.2254 6.24606C15.1522 6.21389 15.0701 6.20804 14.993 6.22952C14.916 6.25099 14.8487 6.29845 14.8027 6.36385L7.87355 16.0647C7.83974 16.1187 7.82181 16.1811 7.82181 16.2448C7.82181 16.3085 7.83974 16.371 7.87355 16.425C7.89636 16.4836 7.93501 16.5347 7.98517 16.5726C8.03533 16.6106 8.09504 16.6338 8.15764 16.6398H13.2714L11.9756 22.4533C11.9518 22.5362 11.9591 22.6249 11.9961 22.7027C12.0331 22.7806 12.0973 22.8422 12.1765 22.876C12.2501 22.9057 12.3315 22.9102 12.4079 22.8889C12.4843 22.8676 12.5516 22.8217 12.5992 22.7582L19.5284 13.0574C19.5711 13.0026 19.5961 12.9361 19.5998 12.8667C19.6035 12.7973 19.5858 12.7284 19.5492 12.6694Z" fill={theme.palette?.icon?.iconPromo} />
      </g>
      <defs>
        <clipPath id="clip0_11706_31399">
          <rect width="16.8027" height="16.8027" fill={theme.palette?.basic?.white} transform="translate(5.59766 6.09961)" />
        </clipPath>
      </defs>
    </SvgIcon>

  )
}

export default LightningIcon
