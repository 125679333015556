import { Box, Dialog, DialogActions, DialogContent, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@mui/material/Backdrop'
import useTheme from '@material-ui/core/styles/useTheme'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import PromoIcon from 'camera-module/cameras/ui/cameras-page/icons/PromoIcon'
import NewProductsIcon from 'camera-module/cameras/ui/cameras-page/icons/NewProductsIcon'
import ProTipsIcon from 'camera-module/cameras/ui/cameras-page/icons/ProTipsIcon'
import LightningIcon from 'camera-module/cameras/ui/cameras-page/icons/LightningIcon'
import MarketingEnvelopeIcon from 'camera-module/cameras/ui/cameras-page/icons/MarketingEnvelopeIcon'

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
    padding: 20,
    borderRadius: 6,
    minHeight: 'fit-content',
  },
  dialogContent: {
    padding: '12px !important',
    textAlign: 'left',
  },
  envelope: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  title: {
    '&.MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.h6?.fontSize,
      fontWeight: 600,
      fontFamily: theme.typography.h6?.fontFamily,
    },
  },
  subtitle: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.body1?.fontFamily,
    },
    textAlign: 'left',
  },
  listRoot: {
    backgroundColor: theme.palette.background?.consent,
    borderRadius: 6,
    paddingTop: 0,
  },
  listItemIcon: {
    minWidth: '45px !important',
  },
  listItemText: {
    '&.MuiListItemText-root': {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: theme.typography.label?.fontSize,
      fontWeight: 600,
      fontFamily: theme.typography.caption2?.fontFamily,
      color: theme.palette.text.primary,
    },
    '& .MuiListItemText-secondary': {
      fontSize: theme.typography.caption1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.caption1?.fontFamily,
      color: theme.palette.text.secondary,
    },
  },
  buttonContainer: {
    justifyContent: 'center',
    padding: '12px !important',
    margin: 0,
  },
  leftButton: {
    marginLeft: 20,
  },
}))

const translationKeys = [
  { icon: PromoIcon, primary: 'app:marketing_consent.promotions', secondary: 'app:marketing_consent.promotions_text' },
  { icon: NewProductsIcon, primary: 'app:marketing_consent.new', secondary: 'app:marketing_consent.new_text' },
  { icon: ProTipsIcon, primary: 'app:marketing_consent.pro', secondary: 'app:marketing_consent.pro_text' },
  { icon: LightningIcon, primary: 'app:marketing_consent.more', secondary: 'app:marketing_consent.more_text' },
]

const MarketingConsentList = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.listRoot}>
      <List>
        { translationKeys.map(({ icon: Icon, primary, secondary }) => (
          <ListItem key={Icon}>
            <ListItemIcon className={classes.listItemIcon}>
              <Icon />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={t(primary)}
              secondary={t(secondary)}
            />
          </ListItem>
        )) }
      </List>
    </Box>
  )
}

const MarketingConsentDialog = ({ open, onClose, title, subtitle, secondaryLabel, primaryLabel, secondaryButtonOnClick, primaryButtonOnClick }) => {
  const classes = useStyles()
  const theme = useTheme()

  const paperProps = theme => {
    return {
      style: {
        maxWidth: 410,
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
        borderRadius: 6,
      },
    }
  }

  const slotProps = theme => {
    return {
      backdrop: { sx: { background: theme.palette.background?.shadow + 'B8' } },
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={paperProps(theme)}
      slots={{ backdrop: Backdrop }}
      slotProps={slotProps(theme)}
    >
      <Box className={classes.dialogWrapper}>
        <DialogContent className={classes.dialogContent}>
          <Box>
            <Box className={classes.envelope}>
              <MarketingEnvelopeIcon />
            </Box>
            <Typography className={classes.title}>
              { title }
            </Typography>
            <Typography className={classes.subtitle} sx={{ mt: 0.45, mb: 3 }}>
              { subtitle }
            </Typography>
          </Box>
          <MarketingConsentList />
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <VoskerSecondaryButton onClick={secondaryButtonOnClick}>
            { secondaryLabel }
          </VoskerSecondaryButton>
          <VoskerPrimaryButton onClick={primaryButtonOnClick} className={classes.rightButton}>
            { primaryLabel }
          </VoskerPrimaryButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default MarketingConsentDialog
