import structuredClone from '@ungap/structured-clone'
import CameraSettingsFormContent from './CameraSettingsFormContent'
import Form from 'form-module/ui'
import Spinner from 'shared-module/components/Spinner.js'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'

const CameraSettingsForm = ({ onSubmit, onReset, dialogActions }) => {
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const [validationSchema, setValidationSchema] = useState()
  const [initial, setInitial] = useState(null)
  const isFirst = useRef(true)

  useEffect(() => {
    if (camera?.config && isFirst.current) {
      setInitial(structuredClone(camera?.config))
      isFirst.current = false
    }
  }, [camera?.config])

  const submit = async (formData, { setSubmitting }) => {
    await onSubmit(formData, initial, setInitial)
    setSubmitting(false)
  }

  if (!camera) {
    return <Spinner />
  }
  const newData = camera.config

  return (
    <Form
      id="camera-settings-form"
      isNewUi
      data={newData}
      validationSchema={validationSchema}
      leaveGuard
      leaveGuardTexts={{
        title: t('camera.settings.leaveGuard.title'),
        content: t('camera.settings.leaveGuard.content'),
      }}
      onSubmit={submit}
    >
      <CameraSettingsFormContent
        dialogActions={dialogActions}
        setValidationSchema={setValidationSchema}
        onReset={onReset}
      />
    </Form>
  )
}

export default CameraSettingsForm
