import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketingConsentDialog from 'vosker/src/consent-module/MarketingConsentDialog'
import useUser from 'user-module/user/core/useUser'
import { ConsentNegativeFinal, ConsentPositiveFinal } from 'vosker/src/consent-module/MarketingConsentFinal'
import useUserActions from 'user-module/user/core/useUserActions'

const MarketingConsentSubscribe = () => {
  const { t } = useTranslation()
  const user = useUser()
  const userActions = useUserActions()
  const oneDayInMS = 24 * 60 * 60 * 1000
  const userId = user.id
  const reminderKey = `reminderCount_${userId}`
  const lastClickKey = `lastMarketingConsentClick_${userId}`
  const dismissedKey = `hasDismissedMarketingConsent_${userId}`
  const [isMarketingConsentDialogOpen, setIsMarketingConsentDialogOpen] = useState(false)
  const [secondaryLabel, setSecondaryLabel] = useState(t('app:marketing_consent.remind_later_button'))
  const [reminderCount, setReminderCount] = useState(0)
  const [isMarketingConsentFinalOpen, setIsMarketingConsentFinalOpen] = useState(false)
  const [isConsentNegativeFinalOpen, setIsConsentNegativeFinalOpen] = useState(false)
  const buildUpdateUser = (values, userId) => ({
    ...values,
    id: userId,
  })
  const hasDismissedPermanently = () => {
    return localStorage.getItem(dismissedKey) === 'true'
  }

  useEffect(() => {
    if (hasDismissedPermanently()) return

    const storedCount = parseInt(localStorage.getItem(reminderKey), 10) || 0
    const lastClickedTime = localStorage.getItem(lastClickKey)

    if (storedCount < 3) {
      if (lastClickedTime) {
        const timePassed = Date.now() - new Date(lastClickedTime).getTime()

        if (timePassed >= oneDayInMS) {
          setIsMarketingConsentDialogOpen(true)
        }
      } else {
        setIsMarketingConsentDialogOpen(true)
      }
    } else {
      setIsMarketingConsentDialogOpen(false)
    }
    setReminderCount(storedCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.marketingEmail])

  useEffect(() => {
    if (isMarketingConsentDialogOpen) {
      if (reminderCount >= 2) {
        setSecondaryLabel(t('app:marketing_consent.no_button'))
      } else {
        setSecondaryLabel(t('app:marketing_consent.remind_later_button'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMarketingConsentDialogOpen, reminderCount])

  const handleMarketingConsentClose = (remindLater) => {
    const newCount = reminderCount + 1
    if (remindLater) {
      localStorage.setItem(reminderKey, newCount)
      localStorage.setItem(lastClickKey, new Date().toISOString())
      setReminderCount(newCount)

      if (newCount >= 3) {
        setIsMarketingConsentDialogOpen(false)
        setIsConsentNegativeFinalOpen(true)
        localStorage.removeItem(reminderKey)
        localStorage.removeItem(lastClickKey)
      } else {
        setIsMarketingConsentDialogOpen(false)
      }
    } else {
      localStorage.setItem(lastClickKey, new Date().toISOString())
      setIsMarketingConsentDialogOpen(false)
    }
  }

  const handlePrimaryButtonOnClick = () => {
    setIsMarketingConsentDialogOpen(false)
    setIsMarketingConsentFinalOpen(true)
  }

  const updateUserProfile = (profileValue) => {
    const updateUser = buildUpdateUser(profileValue, user.id)
    return userActions.update(updateUser)
  }

  const handleConsentFinalClose = () => {
    updateUserProfile({ marketingEmail: true })
    setIsMarketingConsentFinalOpen(false)
  }

  const handleConsentNegativeFinalClose = () => {
    updateUserProfile({ marketingEmail: false })
    localStorage.setItem(dismissedKey, 'true')
    setIsConsentNegativeFinalOpen(false)
    setIsMarketingConsentFinalOpen(false)
  }

  const handleConsentNegativeFinalPrimaryAction = () => {
    setIsConsentNegativeFinalOpen(false)
    setIsMarketingConsentFinalOpen(true)
  }
  return (
    <>
      { !user.marketingEmail && !hasDismissedPermanently() && (
        <MarketingConsentDialog
          open={isMarketingConsentDialogOpen}
          title={t('app:marketing_consent.title')}
          subtitle={t('app:marketing_consent.opt_in')}
          secondaryLabel={secondaryLabel}
          primaryLabel={t('app:marketing_consent.optin_button')}
          secondaryButtonOnClick={() => handleMarketingConsentClose(true)}
          primaryButtonOnClick={() => handlePrimaryButtonOnClick()}
        />
      ) }

      { isMarketingConsentFinalOpen && (
        <ConsentPositiveFinal
          open={isMarketingConsentFinalOpen}
          onClose={() => handleConsentFinalClose()}
        />
      ) }

      { isConsentNegativeFinalOpen && (
        <ConsentNegativeFinal
          open={isConsentNegativeFinalOpen}
          onClose={handleConsentNegativeFinalClose}
          onPrimaryAction={handleConsentNegativeFinalPrimaryAction}
        />
      ) }
    </>

  )
}

export default MarketingConsentSubscribe
