import StreamingIcon from 'assets/icons/video/StreamingIcon'
import videoState from 'video-module/video-clip/core/videoState'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  icon: {
    width: 'auto',
    height: '1.25rem !important',
    marginTop: '0.25rem',
    marginRight: '0.5rem',
    marginLeft: '0.25rem',
  },
}))

const SavedStreamIconOverlay = ({ photo }) => {
  const classes = useStyles()
  const isAStream = videoState.isAStream(photo)

  return isAStream
    ? <StreamingIcon className={classes.icon} />
    : null
}

export default SavedStreamIconOverlay
