import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import usePhotoViewerNavigation from 'photo-module/photo/core/usePhotoViewerNavigation'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import { getPhotos } from 'photo-module/photos/core/photosActions'
import PreviousButton from 'photo-module/photo/ui/photo-viewer/actions-bar/PreviousButton'
import NextButton from 'photo-module/photo/ui/photo-viewer/actions-bar/NextButton'
import downloadStatus from 'photo-module/download/downloadStatus'
import useFilters from 'photo-module/filters/core/useFilters'
import videoState from 'video-module/video-clip/core/videoState'
import VideoClipPlayerContainer from 'video-module/video-clip/ui/video-clip-player/VideoClipPlayerContainer'
import VoskerVideoPreview from 'video-module/video-clip/ui/video-preview/VoskerVideoPreview'
import VoskerLoadImageIcon from 'vosker/src/photos-module/photo/ui/icons/VoskerLoadImageIcon'
import Box from '@material-ui/core/Box'
import VoskerNoImgIcon from 'camera-module/cameras/ui/cameras-page/icons/VoskerNoImgIcon'
import Grid from '@material-ui/core/Grid'
import EventErrorIcon from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/icons/EventErrorIcon'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  photoViewer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.only('sm')]: {
      alignItems: 'initial',
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
      width: '100%',
    },
  },
  photo: {
    maxWidth: '100%',
    maxHeight: '100vh',
  },
  loaderBox: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.basic?.black,
  },
  unavailable: {
    width: '5rem',
    height: '5rem',
  },
  errorContainer: {
    marginTop: '1rem',
    maxHeight: '3.5rem',
    padding: '0.5rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#501717',
    border: '1px solid #FF6961',
    borderRadius: '0.375rem',
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      top: 'calc(100vh - 72px)',
      width: '100%',
    },
  },
  iconContainer: {
    width: '100%',
    height: '100%',
    paddingTop: '1.5rem',
    marginRight: '-1rem',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1rem',
      marginRight: '0rem',
    },
  },
  errorIcon: {
    width: '80%',
    height: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
    },
  },
  message: {
    color: theme.palette.basic?.lightGrey + ' !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.caption1?.fontSize,
      fontFamily: theme.typography.caption1?.fontFamily,
    },
  },
}))

const PhotoViewerContainer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const photo = useSelectedPhoto()
  const filters = useFilters()
  const { reloadGalleryEnd } = usePhotoViewerNavigation(photo?.id)

  const [loaded, setLoaded] = useState(false)
  const [unavailable, setUnavailable] = useState(false)

  useEffect(() => {
    if (reloadGalleryEnd) {
      dispatch(getPhotos(filters))
    }
  }, [reloadGalleryEnd, dispatch, photo, filters])

  useEffect(() => {
    setLoaded(false)
  }, [photo?.id])

  return (
    <Grid container className={classes.photoViewer}>
      <PreviousButton photo={photo} />

      { videoState.isAVideo(photo) || videoState.isAStream(photo)
        ? <VideoClipPlayerContainer photo={photo} />
        : (
          <>
            { !loaded && (
              <Box className={classes.loaderBox}>
                <VoskerLoadImageIcon />
              </Box>
            ) }

            { unavailable && (
              <Box className={classes.loaderBox}>
                <VoskerNoImgIcon inViewer className={classes.unavailable} />
              </Box>
            ) }
            { photo?.urls.preview && (
              <Box className={classes.loaderBox}>
                <VoskerVideoPreview photo={photo} />
              </Box>
            ) }
            <Box className={classes.photoContainer}>
              <img
                className={classes.photo}
                src={photo?.hdStatus === downloadStatus.available ? photo?.urls.hd : photo?.urls.large}
                alt=""
                onLoad={() => setLoaded(true)}
                onError={() => setUnavailable(true)}
              />

              { (photo?.hdVideoStatus === downloadStatus.error || photo?.hdStatus === downloadStatus.error) &&
                (
                  <Grid container item xs={11} sm="auto" className={classes.errorContainer}>
                    <Grid item xs={2} sm={1} className={classes.iconContainer}>
                      <EventErrorIcon className={classes.errorIcon} />
                    </Grid>

                    <Grid item xs="auto">
                      <Typography variant="body1" className={classes.message}>
                        { photo.hdVideoStatus === downloadStatus.error
                          ? t('photo_viewer.full_hd_video.error.text')
                          : t('photo_viewer.hd_photo.error.text') }
                      </Typography>
                    </Grid>
                  </Grid>
                ) }
            </Box>
          </>
          ) }

      <NextButton photo={photo} />
    </Grid>
  )
}

export default PhotoViewerContainer
