import messageActions from 'shared-module/message/messageActions'
import { BAD_GATEWAY, INTERNAL_SERVER_ERROR, NOT_FOUND, SERVER_UNREACHABLE } from 'shared-module/api/httpStatusCode'

const apiError = error => dispatch => {
  if (error.response?.status === NOT_FOUND) {
    dispatch(messageActions.showError('errors.catch_all'))
  } else if (error.response?.status === INTERNAL_SERVER_ERROR ||
    error.response?.status === BAD_GATEWAY ||
    error.response?.status === SERVER_UNREACHABLE) {
    dispatch(messageActions.showError('errors.catch_all'))
  }

  throw error
}
const apiCustomError = error => dispatch => {
  dispatch(messageActions.showError(error))
}

const errorActions = { apiError, apiCustomError }
export default errorActions
