import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  priceContainer: ({ isPlan }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'self-end',
    padding: isPlan ? '0.25rem 0 1rem' : '0.25rem 0 0.5rem',
    [theme.breakpoints.down('xs')]: {
      padding: isPlan ? '1rem 0.2rem' : '0.5rem 0.2rem',
    },
  }),
  dollarSign: ({ isPlan }) => ({
    paddingBottom: '0.375rem',
    fontSize: isPlan ? '1.25rem' : '1rem',
    fontFamily: theme.typography.fontFamilyBold,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0.125rem',
    },
  }),
  price: ({ isPlan }) => ({
    fontSize: isPlan ? '2.625rem' : '1.5rem',
    lineHeight: '1.5rem',
    fontFamily: theme.typography.fontFamilyBold,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
  }),
  decimal: {
    paddingBottom: '0.375rem',
    fontSize: '1.25rem',
    fontFamily: theme.typography.fontFamilyBold,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0.125rem',
    },
  },
  priceFrequency: {
    top: '0.5rem',
    position: 'relative',
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: theme.typography.caption1?.fontSize,
    [theme.breakpoints.down('xs')]: {
      top: '0.25rem',
    },
  },
}))

const Price = ({ price, monthly = false, isPlan = false }) => {
  const classes = useStyles({ isPlan })
  const { t } = useTranslation()

  const isDecimalPrice = price?.toString().includes('.')
  const testId = price === 0 ? 'free-plan-price' : 'plan-price'

  const slitPrice = {
    amount: price?.toString().split('.')[0],
    decimal: price?.toString().split('.')[1],
  }

  return (
    <Box className={classes.priceContainer} data-testid={testId}>
      { price === 0
        ? (
          <>
            <Typography className={classes.dollarSign} mb={2}>$</Typography>
            <Typography className={classes.price} mb={2}>{ price }</Typography>
          </>
          )
        : (
          <>
            <Typography className={classes.dollarSign} mb={2}>$</Typography>
            <Typography className={classes.price} mb={2}>{ slitPrice.amount }</Typography>

            { isDecimalPrice && (
              <Typography className={classes.decimal}>
                .{ slitPrice.decimal }{ slitPrice.decimal?.toString().length === 1 && '0' }
              </Typography>
            ) }

            { monthly && (
              <Typography className={classes.priceFrequency}>
                { t('app:plan.frequency.per_month_short') }
              </Typography>
            ) }
          </>
          ) }
    </Box>
  )
}

export default Price
